import { injectable } from 'tsyringe';
import type { PatientInboundChatAccess } from '../../domain/patient/PatientInboundChatAccess';
import { HttpClientApi } from '@/modules/http-client/public/api';

@injectable()
export class HttpPatientInboundChatAccess implements PatientInboundChatAccess {
  constructor(private readonly httpClient: HttpClientApi) {}

  async enableWriting(patientId: string): Promise<void> {
    await this.httpClient.put(`/api/v0/facility/{facilityId}/patient/${patientId}/dp-chat`, {
      canReply: true,
    });
  }

  async disableWriting(patientId: string): Promise<void> {
    await this.httpClient.put(`/api/v0/facility/{facilityId}/patient/${patientId}/dp-chat`, {
      canReply: false,
    });
  }
}
