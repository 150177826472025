import type { DoctorFilterOption } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOption';

export class DoctorOption implements DoctorFilterOption {
  readonly type = 'doctor';

  private constructor(
    readonly value: string,
    readonly name: string,
  ) {}

  getName(): string {
    return this.name;
  }

  isMatchingDoctor(doctorId: string | null): boolean {
    return this.value === doctorId;
  }

  static make(value: string, name: string): DoctorOption {
    return new DoctorOption(value, name);
  }
}
