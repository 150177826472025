import type { OwnerRepository } from '@/modules/tasks-list/domain/filters/ownership/owner/OwnerRepository';
import { Owner } from '@/modules/tasks-list/domain/filters/ownership/owner/Owner';
import { injectable, inject } from 'tsyringe';
import { RootStoreToken } from '@/common/root';

interface User {
  id: string;
  name: string;
}

interface Store {
  getters: {
    getFacilityPersonnel: User[];
  };
}

@injectable()
export class StoreOwnerRepository implements OwnerRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  all(): Owner[] {
    return this.store.getters.getFacilityPersonnel.map(user => new Owner(user.id, user.name));
  }
}
