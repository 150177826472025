import type { StorageFactory } from '@/common/root/domain/storage/services/StorageFactory';
import { RootLocalStorageFactoryToken } from '@/common/root/providers/RootStorageProvider';
import { inject, injectable } from 'tsyringe';
import { UserApi } from '@/modules/user/public/api/UserApi';
import { LocalSetting } from '../../domain/configuration/LocalSetting';
import type { LocalSettingsRepository } from '../../domain/configuration/LocalSettingsRepository';

@injectable()
export class LocalStorageLocalSettingsRepository implements LocalSettingsRepository {
  constructor(
    @inject(RootLocalStorageFactoryToken)
    private readonly storageFactory: StorageFactory,
    private readonly userApi: UserApi,
  ) {}

  get(name: string): LocalSetting | null {
    const status = this.getStoredItem(name).value;

    return status ? new LocalSetting(name, status) : null;
  }

  set(setting: LocalSetting): void {
    this.getStoredItem(setting.name).set(setting.status);
  }

  private getStoredItem(name: string) {
    const userId = this.userApi.getCurrentUser().id;

    return this.storageFactory.createStorageItemFactory<LocalSetting>(`local-setting-${userId}`)(
      name as keyof LocalSetting,
    );
  }
}
