import type { DpListener } from '@/core/events/DpListener';
import { injectable } from 'tsyringe';
import { CurrentFilterOption } from '@/modules/tasks-list/domain/filters/ownership/CurrentFilterOption';

@injectable()
export class FilterResetListener implements DpListener {
  constructor(private readonly currentFilterOption: CurrentFilterOption) {}

  handle(): void {
    this.currentFilterOption.clear();
  }
}
