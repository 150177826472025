import { injectable } from 'tsyringe';
import type { DoctorRepository } from '@/modules/tasks-list/domain/filters/doctor/doctors/DoctorRepository';
import { Doctor } from '@/modules/tasks-list/domain/filters/doctor/doctors/Doctor';
import { store } from '@/store';
import type { Doctor as FacilityDoctor } from '@/api/models/Doctor';

@injectable()
export class StoreDoctorRepository implements DoctorRepository {
  get(): Doctor[] {
    const doctors: FacilityDoctor[] = store.getters.getUserFacility.docplanner_doctors;

    return doctors.map(({ id, name, surname, prefix }) => new Doctor(id, name, surname, prefix));
  }
}
