import { inject, singleton } from 'tsyringe';
import { ClientManagerToken, ErrorLoggerToken } from '../di/tokens';
import { Orchestrator } from './Orchestrator';
import type { ClientManager } from './client/ClientManager';
import type { ErrorLogger } from './ErrorLogger';

declare global {
  interface Window {
    Pusher?: any;
  }
}

@singleton()
export class ClientReconnectService {
  private static readonly maxDelayInSeconds = 600;

  private attemptNumber = 0;

  private timeout: NodeJS.Timeout | null = null;

  constructor(
    private readonly orchestrator: Orchestrator,
    @inject(ClientManagerToken)
    private readonly clientManager: ClientManager,
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
  ) {}

  initialize(): void {
    if (this.hasPendingTimeout) {
      return;
    }

    if (this.isConnected()) {
      this.finish();
      return;
    }

    this.orchestrator.create();

    this.reconnect();
  }

  finish(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.attemptNumber = 0;
  }

  private isConnected(): boolean {
    return this.clientManager.state === 'connected';
  }

  private get hasPendingTimeout(): boolean {
    return this.timeout !== null;
  }

  private reconnect(): void {
    if (this.isConnected()) {
      this.finish();
      return;
    }

    const nextTimeout = this.getTimeout();

    this.timeout = setTimeout(() => {
      if (this.isConnected()) {
        this.finish();
        return;
      }

      this.orchestrator.create().catch(e => {
        this.errorLogger.logInfoWithContext(
          e,
          {
            reconnectContext: {
              attemptNumber: this.attemptNumber,
              pusherState: this.clientManager.state,
              numberOfPusherInstances: window.Pusher?.instances?.length,
            },
          },
          ['pusher', 'orchestrator', 'reconnect'],
        );
        throw e;
      });
      this.reconnect();
    }, nextTimeout);

    this.attemptNumber += 1;
  }

  private getTimeout(): number {
    const currentTimeout = 2 ** this.attemptNumber * 5 * 1000;
    return Math.min(currentTimeout, ClientReconnectService.maxDelayInSeconds * 1000);
  }
}
