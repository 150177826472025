import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { DoctorRepository } from '@/modules/tasks-list/domain/filters/doctor/doctors/DoctorRepository';
import { StoreDoctorRepository } from '@/modules/tasks-list/adapters/filters/doctor/StoreDoctorRepository';
import {
  DoctorRepositoryToken,
  FilterCurrentStateRepositoryToken,
  OwnerRepositoryToken,
  UserRepositoryToken,
} from '@/modules/tasks-list/di/tokens';
import type { UserRepository } from '@/modules/tasks-list/domain/filters/ownership/user/UserRepository';
import { UserApiUserRepository } from '@/modules/tasks-list/adapters/filters/ownership/UserApiUserRepository';
import type { OwnerRepository } from '@/modules/tasks-list/domain/filters/ownership/owner/OwnerRepository';
import { StoreOwnerRepository } from '@/modules/tasks-list/adapters/filters/ownership/StoreOwnerRepository';
import type { FilterCurrentStateRepository } from '@/modules/tasks-list/domain/filters/ownership/state/FilterCurrentStateRepository';
import { StorageFilterCurrentStateRepository } from '@/modules/tasks-list/adapters/filters/ownership/StorageFilterCurrentStateRepository';

export class FiltersProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<UserRepository>(UserRepositoryToken, UserApiUserRepository);
    container.register<OwnerRepository>(OwnerRepositoryToken, StoreOwnerRepository);

    container.register<FilterCurrentStateRepository>(
      FilterCurrentStateRepositoryToken,
      StorageFilterCurrentStateRepository,
    );

    container.register<DoctorRepository>(DoctorRepositoryToken, StoreDoctorRepository);
  }
}
