import { inject, injectable } from 'tsyringe';
import type { HttpRequestConfig } from '@/modules/http-client/domain/request/HttpRequestConfig';
import type {
  HttpRequestExecutor,
  QueryParams,
} from '@/modules/http-client/domain/request/HttpRequestExecutor';
import { HttpInterceptionManager } from '@/modules/http-client/domain/interception/HttpInterceptionManager';
import { BaseHttpRequestConfigFactory } from '@/modules/http-client/domain/request/BaseHttpRequestConfigFactory';
import type { HttpInterception } from '@/modules/http-client/domain/interception/HttpInterception';
import { ErrorTrackerToken, HttpRequestExecutorToken } from '@/modules/http-client/di/tokens';
import type { ErrorTracker } from '@/modules/http-client/domain/ErrorTracker';
import type { HttpClientError } from '@/modules/http-client/domain/client/HttpClientError';

@injectable()
export class BaseHttpClientApi {
  private readonly baseConfig: HttpRequestConfig;

  private pendingPromises = new Map<string, Promise<any>>();

  constructor(
    @inject(HttpRequestExecutorToken)
    private readonly httpRequestExecutor: HttpRequestExecutor,
    private readonly httpInterceptionManager: HttpInterceptionManager,
    @inject(ErrorTrackerToken)
    private readonly errorTracker: ErrorTracker,
    baseHttpRequestConfigFactory: BaseHttpRequestConfigFactory,
  ) {
    this.baseConfig = baseHttpRequestConfigFactory.make();
  }

  async get<T>(url: string, params?: QueryParams): Promise<T> {
    return this.makeRequest<T>({ method: 'get', url, params });
  }

  async post<T>(url: string, data?: unknown): Promise<T> {
    return this.makeRequest<T>({ method: 'post', url, data });
  }

  async postWithFullResponse<T>(
    url: string,
    data?: unknown,
    params?: QueryParams,
  ): Promise<{ data: T }> {
    return this.makeRequestWithFullResponse<T>({ method: 'post', url, data, params });
  }

  async patch<T>(url: string, data?: unknown): Promise<T> {
    return this.makeRequest<T>({ method: 'patch', url, data });
  }

  async put<T>(url: string, data?: unknown): Promise<T> {
    return this.makeRequest<T>({ method: 'put', url, data });
  }

  async delete<T>(url: string): Promise<T> {
    return this.makeRequest<T>({ method: 'delete', url });
  }

  addInterceptor(interceptor: HttpInterception): void {
    this.httpInterceptionManager.addInterceptor(interceptor);
  }

  private async makeRequest<T>(requestConfig: HttpRequestConfig): Promise<T> {
    const config = await this.decorateRequestConfig(requestConfig);
    const requestToken = this.makeToken(config);

    try {
      const { data } = await this.getOrCreatePromise<{ data: T }>(requestToken, () =>
        this.httpRequestExecutor.request<{ data: T }>(config),
      );

      return data;
    } catch (error) {
      await this.handleErrorResponse(error);

      throw error;
    } finally {
      this.removePromise(requestToken);
    }
  }

  private async makeRequestWithFullResponse<T>(
    requestConfig: HttpRequestConfig,
  ): Promise<{ data: T }> {
    const config = await this.decorateRequestConfig(requestConfig);
    const requestToken = this.makeToken(config);

    try {
      return this.getOrCreatePromise<{ data: T }>(requestToken, () =>
        this.httpRequestExecutor.request<{ data: T }>(config),
      );
    } catch (error) {
      this.removePromise(requestToken);

      await this.handleErrorResponse(error);

      throw error;
    } finally {
      this.removePromise(requestToken);
    }
  }

  private async decorateRequestConfig(
    requestConfig: HttpRequestConfig,
  ): Promise<HttpRequestConfig> {
    const interceptors: HttpInterception[] = this.httpInterceptionManager.getInterceptors();
    let config: HttpRequestConfig = { ...this.baseConfig, ...requestConfig };

    for (const interceptor of interceptors) {
      config = (await interceptor.onRequest?.(config)) ?? config;
    }

    return config;
  }

  private async handleErrorResponse(error: HttpClientError): Promise<void> {
    this.errorTracker.logError(
      error,
      ['http-client', error.normalizedUrl, error.method, error.statusCodeString],
      {
        'http-error.url': error.normalizedUrl,
        'http-error.method': error.method,
        'http-error.status': error.statusCodeString,
      },
    );

    const interceptors: HttpInterception[] = this.httpInterceptionManager.getInterceptors();

    for (const interceptor of interceptors) {
      await interceptor.onError?.(error);
    }
  }

  private getOrCreatePromise<T>(token: string, callback: () => Promise<T>): Promise<T> {
    if (this.pendingPromises.has(token)) {
      return this.pendingPromises.get(token) as Promise<T>;
    }

    const promise = callback();

    this.pendingPromises.set(token, promise);

    return promise;
  }

  private removePromise(token: string): void {
    this.pendingPromises.delete(token);
  }

  private makeToken(requestConfig: HttpRequestConfig): string {
    return JSON.stringify(requestConfig);
  }
}
