export class Doctor {
  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly prefix?: string,
  ) {}

  get fullName(): string {
    return this.prefix
      ? `${this.prefix} ${this.firstName} ${this.lastName}`
      : `${this.firstName} ${this.lastName}`;
  }
}
