import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ErrorTracker } from '@/modules/error-logger/domain/ErrorTracker';
import { router } from '@/router';
import { getTopLevelDomain } from '@/utils/domain';

export class ErrorTrackingProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const errorTracker = container.resolve(ErrorTracker);

    this.decorateAllErrors(errorTracker);
    this.registerIgnoredErrors(errorTracker);
  }

  private decorateAllErrors(errorTracker: ErrorTracker): void {
    if (router) {
      errorTracker.catchAllErrors(() => ({
        tags: {
          'router.name': router.currentRoute.value.name?.toString() ?? 'unknown',
          'router.fullPath': router.currentRoute.value.fullPath,
          'router.redirectedFrom': router.currentRoute.value?.redirectedFrom?.fullPath ?? 'unknown',
        },
      }));
    }

    errorTracker.catchAllErrors(() => ({
      tags: {
        marketplace: getTopLevelDomain(),
      },
    }));
  }

  private registerIgnoredErrors(errorTracker: ErrorTracker): void {
    errorTracker.ignoreError(/^Failed to fetch$/);
    errorTracker.ignoreError(/^Importing a module script failed.$/);
    errorTracker.ignoreError(/^Unable to preload CSS for.*/);
    errorTracker.ignoreError(/^Failed to fetch dynamically imported module.*/);
    errorTracker.ignoreError(/^error loading dynamically imported module.*/);
  }
}
