import { createGtm } from '@gtm-support/vue-gtm';
import config from '@/config/config.json';
import { router } from '@/router';

export const gtmPlugin = createGtm({
  id: config.GTM_ID,
  enabled: import.meta.env.PROD || Boolean(import.meta.env.VITE_APP_GTM_ACTIVE),
  debug: Boolean(import.meta.env.VITE_APP_GTM_ACTIVE),
  vueRouter: router,
  ignoredViews: [],
});
