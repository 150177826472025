import { injectable } from 'tsyringe';
import type { Translator } from '@/common/root/domain/Translator';
import type { VueI18n } from 'vue-i18n';

@injectable()
export class VueI18nTranslator implements Translator {
  constructor(private readonly i18n: VueI18n) {}

  translate(key: string, params?: Record<string, string>): string {
    if (params) {
      return this.i18n.t(key, params);
    }

    return this.i18n.t(key);
  }

  translateChoice(key: string, choice: number, params?: Record<string, string>): string {
    if (params) {
      return this.i18n.t(key, params, choice);
    }

    return this.i18n.t(key, choice);
  }

  hasTranslation(key: string): boolean {
    return this.i18n.te(key);
  }
}
