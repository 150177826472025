import type { StoreOptions, Plugin } from 'vuex';
import { prop } from '@/utils/functions';
import { initEntities } from '@dp-vue/entities/dist';
import type { RootState } from '@/store/types';
import { appStatus } from './modules/app-status';
import { imports } from './modules/imports';
import { patient } from './modules/patient';
import { profile } from './modules/profile';
import { search } from './modules/search';
import { settings } from './modules/settings';
import { stats } from './modules/stats';
import { task } from './modules/task';
import { upcomingVisits } from './modules/upcoming-visits';
import { user } from './modules/user';
import { validationErrors } from './modules/validation-errors';
import { wizard } from './modules/wizard';
import { phoneWidgetPlugin } from './plugins/phoneWidgetPlugin';
import { timerPlugin } from './plugins/timerPlugin';
import { usersStatusManagerPlugin } from './plugins/usersStatusManagerPlugin';

const plugins: Plugin<RootState>[] = [
  { plugin: initEntities(), order: 1 },
  { plugin: (...args) => phoneWidgetPlugin(...args), order: 3 },
  { plugin: timerPlugin, order: 3 },
  { plugin: usersStatusManagerPlugin, order: 3 },
]
  .sort(({ order: a }, { order: b }) => a - b)
  .map(prop('plugin'));

export const getStoreOptions = (): StoreOptions<RootState> => ({
  modules: {
    appStatus,
    imports,
    patient,
    profile,
    search,
    settings,
    stats,
    task,
    upcomingVisits,
    user,
    validationErrors,
    wizard,
  },

  plugins,

  mutations: {
    DELETE_ALL_ENTITIES(state: any) {
      delete state.entities;
      state.entities = {};
    },

    DELETE_ENTITIES_GROUP(state, groupName) {
      delete state.entities[groupName];
    },
  },

  actions: {
    DELETE_ALL_ENTITIES_ACTION({ commit }) {
      commit('DELETE_ALL_ENTITIES');
    },

    DELETE_ENTITIES_GROUP_ACTION({ commit }, groupName) {
      commit('DELETE_ENTITIES_GROUP', groupName);
    },
  },
});
