import type { Owner } from '@/modules/task/domain/owner/Owner';
import type { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { VoiceMenu } from '@/modules/task/domain/voice-menu/VoiceMenu';
import type { Ownable } from '@/modules/task/domain/owner/Ownable';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import type { Voicemail } from '../voicemail/Voicemail';
import type { Caller } from './Caller';
import type { TaskStatus } from './TaskStatus';
import type { TaskTab } from './TaskTab';
import type { TaskType } from './TaskType';

export class Task implements Ownable {
  static newToDoId = 'new-task';

  constructor(
    readonly id: string,
    readonly type: TaskType,
    readonly status: TaskStatus,
    readonly tab: TaskTab,
    readonly ownerId: OwnerId,
    readonly patientId: string,
    readonly voiceMenu: VoiceMenu,
    readonly comment: string,
    readonly isAnonymous: boolean,
    readonly hasPriority: boolean,
    readonly caller: Caller,
    readonly expiresAt: string,
    readonly createdAt: string,
    readonly voicemail: Voicemail,
    readonly subjectId?: string,
    readonly dpChatInboundCommunicationAllowed: boolean | null = null,
  ) {}

  get isInboundForm(): boolean {
    return this.type === 'inbound-form';
  }

  get isNewToDo(): boolean {
    return this.id === Task.newToDoId;
  }

  get isOutboundToDo(): boolean {
    return this.type === 'outbound-call';
  }

  get isOutboundVisitConfirmation(): boolean {
    return this.type === 'outbound-visit-confirmation';
  }

  get isClosed(): boolean {
    return [
      'resolved',
      'visit-confirmation-successful',
      'online-booking-conversion',
      'abandoned',
      'archived',
      'expired',
    ].includes(this.status);
  }

  get isExpiredStatus(): boolean {
    return this.status === 'expired';
  }

  get isArchived(): boolean {
    return this.status === 'archived';
  }

  get isOpen(): boolean {
    return this.status === 'open';
  }

  get isAbandoned(): boolean {
    return this.status === 'abandoned';
  }

  get isBookedOnline(): boolean {
    return this.status === 'online-booking-conversion';
  }

  get isClosedBySubject(): boolean {
    return this.status === 'resolved';
  }

  get isPendingTab(): boolean {
    return this.tab === 'open';
  }

  get isTodoTab(): boolean {
    return this.tab === 'todo';
  }

  get hasComment(): boolean {
    return this.comment.trim() !== '';
  }

  get hasSubject(): boolean {
    return !!this.subjectId;
  }

  get isConvertedVoicemail(): boolean {
    return this.voicemail.converted;
  }

  get isInboundNonReception(): boolean {
    return this.type === 'inbound-non-reception';
  }

  get hasOwner(): boolean {
    return !this.ownerId.isEmpty;
  }

  isOwnedByAnotherUser(ownerRepository: OwnerRepository): boolean {
    if (!this.hasOwner) {
      return false;
    }

    return !this.isMine(ownerRepository);
  }

  isMine(ownerRepository: OwnerRepository): boolean {
    return this.isOwnedBy(ownerRepository.getMe());
  }

  isOwnedBy(owner: Owner): boolean {
    return owner.id.isEqual(this.ownerId);
  }

  isExpired(): boolean {
    return new Date(this.expiresAt).getTime() < Date.now();
  }
}
