<template>
  <div>
    <app-status-banner :class="$b('banner')">
      <template #icon>
        <dp-icon-sync-problem color="black" :size="24" />
      </template>
      <i18n-t tag="div" keypath="app_status.banner.short_message.downtime">
        <template #readMoreAnchor>
          <a :class="$b('link')" @click.prevent="openModal">
            {{ $t('app_status.banner.read_more') }}
          </a>
        </template>
        <template #checkStatusAnchor>
          <a :href="statusPageUrl" target="_blank" :class="$b('link')">{{
            $t('app_status.banner.check_status')
          }}</a>
        </template>
      </i18n-t>
    </app-status-banner>
    <dp-modal v-model="isDetailsModelOpen" :class="$b('modal')" :show-close-btn="false">
      <h2 :class="$b('title')">
        {{ $t('app_status.modal.title.downtime') }}
      </h2>

      <p v-sanitize-html="$t('app_status.modal.long_message.downtime')" class="my-2" />

      <a :class="$b('link', { primary: true })" :href="statusPageUrl">
        {{ statusPageUrl }}
      </a>

      <template #footer="{ close }">
        <dp-button size="lg" @click="close">
          {{ $t('app_status.modal.close_button.downtime') }}
        </dp-button>
      </template>
    </dp-modal>
  </div>
</template>

<script lang="ts">
import { DpModal, DpButton } from '@dp-ui-kit/vue';
import { DpIconSyncProblem } from '@dp-ui-kit/icons';
import { Component, Vue } from 'vue-facing-decorator';

import config from '@/config/config.json';
import AppStatusBanner from '@/components/app-status/AppStatusBanner.vue';

@Component({
  name: 'AppStatusDowntime',
  components: { AppStatusBanner, DpModal, DpButton, DpIconSyncProblem },
})
export default class AppStatusDowntime extends Vue {
  isDetailsModelOpen = false;

  statusPageUrl = config.STATUSPAGE_URL;

  openModal() {
    this.isDetailsModelOpen = true;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-app-status-downtime {
  &__banner {
    background: v.$warning;
    color: v.$black;
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &--primary {
      color: v.$primary-dark;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.25rem;
  }

  &__modal {
    text-align: center;
  }
}
</style>
