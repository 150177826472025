import { inject, injectable } from 'tsyringe';
import { $GET_ENTITIES_BY_ID } from '@dp-vue/entities';
import type { UserRepository } from '@/modules/user/domain/UserRepository';
import { User } from '@/modules/user/domain/User';
import { compose, prop, truthy, isIncludedIn } from '@/utils/functions';
import { RootStoreToken } from '@/common/root';
import type { Workstation } from './types/Workstation';
import type { StoreDenormalizedUser } from './types/StoreDenormalizedUser';
import type { StoreUser } from './types/StoreUser';
import type { Store } from './types/Store';
import type { StoreMedia } from './types/StoreMedia';
import { CurrentUser } from '../domain/CurrentUser';
import { storeName } from '@/common/real-time-communication/ui/store/pusher';

@injectable()
export class StoreUserRepository implements UserRepository {
  constructor(@inject(RootStoreToken) private readonly store: Store) {}

  get(userId: string): User {
    const storeUser: StoreUser | undefined = this.store.getters[$GET_ENTITIES_BY_ID]('user', [
      userId,
    ])[0];

    if (!storeUser) {
      return User.empty();
    }

    if (!storeUser.avatar) {
      return new User(storeUser.id, storeUser.name, null);
    }

    const avatarMedia: StoreMedia = this.store.getters[$GET_ENTITIES_BY_ID]('media', [
      storeUser.avatar,
    ])[0];

    return new User(storeUser.id, storeUser.name, avatarMedia.url);
  }

  getCurrentUser(): CurrentUser {
    const currentUser = this.store.getters.getUser;
    const currentWorkstation = this.store.getters['settings/reception/getCurrentUserPhone'];

    const { avatar } = this.get(currentUser.id);

    return new CurrentUser(
      currentUser.id,
      currentUser.name,
      currentUser.isManager,
      currentUser.email ?? null,
      currentUser.created_at,
      currentUser.roles,
      avatar,
      currentWorkstation?.id ?? null,
      currentUser.phone_number ?? null,
    );
  }

  allActive(): User[] {
    const activeUsersIds = this.store.getters[`${storeName}/getActiveUsersIds`];
    const workstations: Workstation[] = this.store.getters['settings/reception/getReceptionPhones'];
    const signedInUsers: StoreDenormalizedUser[] = workstations
      .map(prop('current_user'))
      .filter(truthy);
    const activeUsers: StoreDenormalizedUser[] = signedInUsers.filter(
      compose(isIncludedIn(activeUsersIds), prop('id')),
    );

    return activeUsers.map((user: StoreDenormalizedUser) => ({
      id: user.id,
      name: user.name,
      avatar: user.avatar?.url ?? null,
    }));
  }

  all(): User[] {
    return this.store.getters.getFacilityPersonnel.map(
      (user: StoreDenormalizedUser) => new User(user.id, user.name, user.avatar?.url ?? null),
      this,
    );
  }
}
