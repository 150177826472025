import type { FilterOption } from '@/modules/tasks-list/domain/filters/ownership/options/FilterOption';

export class EveryoneOption implements FilterOption {
  readonly value = 'everyone';

  readonly type = 'everyone';

  getName(translationFunction: (key: string) => string): string {
    return translationFunction('task_filter.everyone');
  }

  isMatchingOwner(): boolean {
    return true;
  }

  static make(): EveryoneOption {
    return new EveryoneOption();
  }
}
