import type { DoctorFilterOption } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOption';
import { EveryoneOption } from '@/modules/tasks-list/domain/filters/doctor/options/types/EveryoneOption';
import { singleton } from 'tsyringe';
import { DoctorFilterOptionList } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOptionList';

@singleton()
export class DoctorFilter {
  constructor(private readonly doctorFilterOptionList: DoctorFilterOptionList) {}

  private currentOption: DoctorFilterOption = EveryoneOption.make();

  getCurrentOption(): DoctorFilterOption {
    return this.currentOption;
  }

  setCurrentOptionValue(value: string): void {
    this.currentOption = this.doctorFilterOptionList.getOptionByValue(value);
  }

  getOptions(): DoctorFilterOption[] {
    return this.doctorFilterOptionList.getOptions();
  }

  get isEmpty(): boolean {
    return !this.doctorFilterOptionList.hasDoctors;
  }
}
