import { errorTracker } from '@/modules/error-logger/public/api';
import type { DpProvider } from '@/core/di/DpProvider';
import { store } from '@/store';

export class ErrorTrackingProvider implements DpProvider {
  boot(): void {
    const tracker = errorTracker();

    tracker.catchAllErrors(() => {
      const id = store.getters.getUserFacility.id;
      const name = store.getters.getUserFacility.name;

      if (!id || !name) {
        return {};
      }

      return {
        tags: {
          'facility.id': id,
          'facility.name': name,
        },
      };
    });
  }
}
