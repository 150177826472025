import { injectable } from 'tsyringe';
import { MessageTemplate } from '../../domain/message-templates/MessageTemplate';
import { MessageTemplateStatusMapper } from './MessageTemplateStatusMapper';
import type { InputMessageTemplate } from './types/InputMessageTemplate';

@injectable()
export class MessageTemplateFactory {
  makeMany(inputMessageTemplates: InputMessageTemplate[]): MessageTemplate[] {
    return inputMessageTemplates.map(this.make, this);
  }

  parseToBackend(messageTemplate: MessageTemplate): Partial<InputMessageTemplate> {
    return {
      id: messageTemplate.id,
      name: messageTemplate.name,
      body: messageTemplate.body,
      status: MessageTemplateStatusMapper.toBackend(messageTemplate.status),
    };
  }

  private make(inputMessageTemplate: InputMessageTemplate): MessageTemplate {
    return new MessageTemplate(
      inputMessageTemplate.id,
      inputMessageTemplate.name,
      inputMessageTemplate.body,
      MessageTemplateStatusMapper.toDomain(inputMessageTemplate.status),
    );
  }
}
