import type { DpProvider } from '@/core/di/DpProvider';
import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { LocalStorageLocalSettingsRepository } from '../../adapters/configuration/LocalStorageLocalSettingsRepository';
import type { LocalSettingsRepository } from '../../domain/configuration/LocalSettingsRepository';
import { LocalSettingsRepositoryToken } from '../token';

@injectable()
export class ConfigurationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<LocalSettingsRepository>(
      LocalSettingsRepositoryToken,
      LocalStorageLocalSettingsRepository,
    );
  }
}
