import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { StoreTextChannelRepository } from '@/modules/tasks-list/adapters/text-channel/StoreTextChannelRepository';
import { HttpTaskRepository } from '@/modules/tasks-list/adapters/task-feed/HttpTaskRepository';
import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { HttpMediaRepository } from '../../adapters/create-task/HttpMediaRepository';
import { LocalizedTemplatePathFactory } from '../../adapters/create-task/LocalizedTemplatePathFactory';
import { PatientApiPatientRepository } from '../../adapters/create-task/PatientApiPatientRepository';
import { HttpTaskCreationRepository } from '../../adapters/HttpTaskCreationRepository';
import type { MediaRepository } from '../../domain/create-task/MediaRepository';
import type { PatientRepository } from '../../domain/create-task/PatientRepository';
import type { TemplatePathFactory } from '../../domain/create-task/TemplatePathFactory';
import type { TaskCreationRepository } from '../../domain/create-task/TaskCreationRepository';
import type { TextChannelRepository } from '../../domain/text-channel/TextChannelRepository';
import {
  MediaRepositoryToken,
  PatientRepositoryToken,
  TaskCreationRepositoryToken,
  TemplatePathRepositoryToken,
  TextChannelRepositoryToken,
  TaskRepositoryToken,
  TaskCounterRepositoryToken,
  TaskEntityManagerToken,
  PatientRequestViewTasksFilterToken,
} from '../tokens';
import type { TaskCounterRepository } from '@/modules/tasks-list/domain/counters/TaskCounterRepository';
import { StoreTaskCounterRepository } from '@/modules/tasks-list/adapters/counters/StoreTaskCounterRepository';
import { StoreTaskEntityManager } from '@/modules/tasks-list/adapters/task-feed/StoreTaskEntityManager';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';
import { HttpPatientRequestViewTasksFilter } from '@/modules/tasks-list/adapters/task-feed/HttpPatientRequestViewTasksFilter';
import type { PatientRequestViewTasksFilter } from '@/modules/tasks-list/domain/task-feed/views/PatientRequestViewTasksFilter';

export class RepositoryProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TemplatePathFactory>(
      TemplatePathRepositoryToken,
      LocalizedTemplatePathFactory,
    );
    container.register<TextChannelRepository>(
      TextChannelRepositoryToken,
      StoreTextChannelRepository,
    );
    container.register<MediaRepository>(MediaRepositoryToken, HttpMediaRepository);
    container.register<PatientRepository>(PatientRepositoryToken, PatientApiPatientRepository);
    container.register<TaskCreationRepository>(
      TaskCreationRepositoryToken,
      HttpTaskCreationRepository,
    );
    container.register<StoreTextChannelRepository>(
      TextChannelRepositoryToken,
      StoreTextChannelRepository,
    );

    container.register<TaskRepository>(TaskRepositoryToken, HttpTaskRepository);

    container.register<TaskEntityManager>(TaskEntityManagerToken, StoreTaskEntityManager);

    container.register<TaskCounterRepository>(
      TaskCounterRepositoryToken,
      StoreTaskCounterRepository,
    );

    container.register<PatientRequestViewTasksFilter>(
      PatientRequestViewTasksFilterToken,
      HttpPatientRequestViewTasksFilter,
    );
  }
}
