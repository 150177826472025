import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { ConnectionObserver } from '../../domain/connection/ConnectionObserver';

export class ConnectionProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const connectionObserver = container.resolve(ConnectionObserver);
    connectionObserver.init();
  }
}
