import type { DpProvider } from '@/core/di/DpProvider';
import { errorTracker } from '@/modules/error-logger/public/api';
import { PhoneWidgetError } from '@/modules/phone-widget/domain/webphone/PhoneWidgetError';

export class ErrorTrackingProvider implements DpProvider {
  boot(): void {
    const tracker = errorTracker();

    tracker.catchError(
      error => error instanceof PhoneWidgetError,
      () => ({
        tags: {
          module: 'phone-widget',
        },
      }),
    );

    tracker.catchError(
      error => error.name === 'InvalidArgumentError',
      error => ({
        tags: {
          vendor: 'twilio',
          module: 'phone-widget',
        },
        fingerprint: ['twilio', 'invalid-argument', error.message],
      }),
    );

    tracker.catchError(
      error => error.name === 'InvalidStateError',
      error => ({
        tags: {
          vendor: 'twilio',
          module: 'phone-widget',
        },
        fingerprint: ['twilio', 'invalid-state', error.message],
      }),
    );

    tracker.catchError(
      error => error.name === 'NotSupportedError',
      error => ({
        tags: {
          vendor: 'twilio',
          module: 'phone-widget',
        },
        fingerprint: ['twilio', 'not-supported', error.message],
      }),
    );
  }
}
