import { singleton } from 'tsyringe';

@singleton()
export class ErrorIgnoringCollection {
  private readonly ignoredErrors: RegExp[] = [];

  get(): RegExp[] {
    return this.ignoredErrors;
  }

  add(matchingRegex: RegExp): void {
    this.ignoredErrors.push(matchingRegex);
  }
}
