import { HelpCenterApi } from '@/modules/help-widget/public/api';
import { injectable } from 'tsyringe';
import type { FeedbackForm } from '../domain/FeedbackForm';

@injectable()
export class HelpWidgetFeedbackForm implements FeedbackForm {
  constructor(private readonly helpCenter: HelpCenterApi) {}
  async open(): Promise<void> {
    await this.helpCenter.openFeedbackForm();
  }
}
