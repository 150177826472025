import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { HttpClientProvider } from './providers/HttpClientProvider';
import { ErrorTrackingProvider } from '@/modules/http-client/di/providers/ErrorTrackingProvider';

export class HttpClientModule implements DpModule {
  providers(): IDpProvider[] {
    return [ErrorTrackingProvider, HttpClientProvider];
  }
}
