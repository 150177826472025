import type { Message } from '@/modules/timeline/domain/message-composer/message/Message';
import { Owner } from '@/modules/timeline/domain/owner/Owner';
import type { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { BodyPartsFactory } from '@/modules/timeline/domain/message-composer/message/body/BodyPartsFactory';
import type { BodyPart } from '@/modules/timeline/domain/message-composer/message/body/BodyPart';

export class SmsMessage implements Message {
  constructor(
    readonly id: string,
    readonly createdAt: IsoDate,
    readonly direction: 'inbound' | 'outbound',
    readonly body: string,
    readonly owner: Owner | null,
    readonly sid: string,
  ) {}

  get bodyParts(): BodyPart[] {
    if (!this.body.length) {
      return [];
    }

    return BodyPartsFactory.make(this.body);
  }
}
