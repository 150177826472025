import { singleton } from 'tsyringe';
import type { ErrorMatchingFunction } from '@/modules/error-logger/domain/decoration/ErrorMatchingFunction';
import type { ErrorDecoratorFunction } from '@/modules/error-logger/domain/decoration/ErrorDecoratorFunction';

interface ErrorHandlerConfig {
  matchingFunction: ErrorMatchingFunction;
  decoratorFunction: ErrorDecoratorFunction;
}

@singleton()
export class ErrorDecorationCollection {
  private readonly decorators: ErrorHandlerConfig[] = [];

  get(): ErrorHandlerConfig[] {
    return this.decorators;
  }

  add(matchingFunction: ErrorMatchingFunction, decoratorFunction: ErrorDecoratorFunction): void {
    this.decorators.push({
      matchingFunction,
      decoratorFunction,
    });
  }
}
