import { inject, singleton } from 'tsyringe';
import { TaskUpdatedEventPublisherToken } from '@/modules/tasks-list/di/tokens';
import { TaskIdQueueHandler } from './TaskIdQueueHandler';
import type { TaskUpdatedEventPublisher } from '../../events/TaskUpdatedEventPublisher';
import { TaskUpdatedFactory } from '../../events/TaskUpdatedFactory';
import { PayloadStorage } from '../queue/PayloadStorage';
import { Queue } from '../queue/Queue';
import { ChunkQueueRunner } from '../queue/ChunkQueueRunner';
import { QueueConsumer } from '../queue/QueueConsumer';

/**
 * It's a controller for a queue. Contains API that should be used to schedule processing data.
 * It accepts new requests to process pusher data and supervises the process:
 *   - activates necessary trackers
 *   - stores extra data obtained with pusher request
 *   - makes an http requests to obtain task data
 *   - defines callback {@link QueueHandler} that should be execute for each collection of items, here up to 6 items are processed at once
 *   - emits domain event {@link TaskUpdatedEvent} for each processed item with valid extra data obtained from pusher
 */
@singleton()
export class TaskIdQueueSupervisor {
  private readonly queue: Queue = new Queue();

  private readonly queueTimeout = 1500;

  private readonly queueConsumer: QueueConsumer;

  constructor(
    private readonly taskIdQueueHandler: TaskIdQueueHandler,
    private readonly payloadStorage: PayloadStorage,
    @inject(TaskUpdatedEventPublisherToken)
    private readonly taskUpdatedEventPublisher: TaskUpdatedEventPublisher,
    private readonly taskUpdatedFactory: TaskUpdatedFactory,
  ) {
    const queueChunkRunner = new ChunkQueueRunner(this.handleChunk.bind(this));
    this.queueConsumer = new QueueConsumer(queueChunkRunner, this.queue, this.queueTimeout);
  }

  addToQueue(taskId: string, updateTriggers: string[]): void {
    this.payloadStorage.merge(taskId, updateTriggers);
    this.queue.enqueueOrUpdate(taskId);
    this.queueConsumer.consume();
  }

  reset(): void {
    this.queueConsumer.reset();
    this.queue.reset();
    this.payloadStorage.reset();
  }

  private async handleChunk(taskIds: string[]): Promise<void> {
    await Promise.all(taskIds.map(this.handleTask, this));
  }

  private async handleTask(taskId: string): Promise<void> {
    await this.taskIdQueueHandler.handle(taskId);
    this.taskUpdatedEventPublisher.publish(
      this.taskUpdatedFactory.makeWithoutPayload(
        taskId,
        this.payloadStorage.popAccumulated(taskId),
      ),
    );
  }
}
