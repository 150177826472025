import { injectable } from 'tsyringe';
import { SmsMessage } from '@/modules/timeline/domain/message-composer/message/SmsMessage';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { UserOwnerRepository } from '../owner/UserOwnerRepository';
import type { StoreMessage } from './StoreMessage';

@injectable()
export class StoreSmsMessageFactory {
  constructor(private readonly ownerRepository: UserOwnerRepository) {}

  makeMany(messages: StoreMessage[], timezone: string): SmsMessage[] {
    return messages.map(message => this.make(message, timezone));
  }

  private make(message: StoreMessage, timezone: string): SmsMessage {
    return new SmsMessage(
      message.id,
      IsoDate.make(message.created_at, timezone),
      this.mapDirection(message.type),
      message.body,
      message.owner ? this.ownerRepository.find(message.owner) : null,
      message.sid,
    );
  }

  private mapDirection(direction: StoreMessage['type']): SmsMessage['direction'] {
    if (direction === 'outbound-template') {
      return 'outbound';
    }

    return direction;
  }
}
