import type { DpProvider } from '@/core/di/DpProvider';
import { errorTracker } from '@/modules/error-logger/public/api';

export class ErrorTrackingProvider implements DpProvider {
  boot(): void {
    const tracker = errorTracker();

    /**
     * @link https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
     */
    tracker.ignoreError(/^ResizeObserver loop limit exceeded$/);
    tracker.ignoreError(/^ResizeObserver loop completed with undelivered notifications.$/);
  }
}
