import { RootStoreToken } from '@/common/root';
import type { CallbackFactory } from '../domain/CallbackFactory';
import { delay, inject, injectable } from 'tsyringe';
import type { Callback } from '../domain/Callback';
import type { Store } from 'vuex';
import { PROCESS_PATIENT_ACTION } from '@/store/modules/patient';
import { PingApi } from '@/modules/user/public/api/PingApi';
import type { PresenceChannel } from 'pusher-js';
import { RealTimeCommunicationTaskCallback } from '@/modules/tasks-list/public/api/RealTimeCommunicationTaskCallback';
import { storeName } from '../ui/store/pusher';

type Task = any;

@injectable()
export class StoreCallbackFactory implements CallbackFactory {
  constructor(
    @inject(RootStoreToken)
    private readonly store: Store<unknown>,
    @inject(delay(() => PingApi))
    private readonly pingApi: PingApi,
    @inject(delay(() => RealTimeCommunicationTaskCallback))
    private readonly taskCallback: RealTimeCommunicationTaskCallback,
  ) {}

  makeForPresenceChannel(presenceChannel: PresenceChannel): Callback[] {
    const setActiveUsers = () => {
      this.store.commit(
        `${storeName}/SET_ACTIVE_USERS`,
        Object.values(presenceChannel.members.members),
      );
    };

    return [
      {
        name: 'pusher:member_added',
        handler: setActiveUsers,
      },
      {
        name: 'pusher:member_removed',
        handler: setActiveUsers,
      },
      {
        name: 'pusher:subscription_succeeded',
        handler: setActiveUsers,
      },
      this.facility,
      this.patient,
      this.patientUpload,
      this.presence,
      this.upcomingVisit,
      this.verification,
    ];
  }
  makeForPrivateChannel(): Callback[] {
    return [this.task];
  }

  cleanUpCallbacks(): void {
    this.taskCallback.reset();
  }

  private get facility(): Callback {
    return {
      name: 'facility',
      handler: () => this.store.dispatch('GET_FACILITY_ACTION'),
    };
  }

  private get patient(): Callback {
    return {
      name: 'patient',
      handler: (patient: unknown): void => {
        this.store.dispatch(PROCESS_PATIENT_ACTION, patient);
      },
    };
  }

  private get patientUpload(): Callback {
    return {
      name: 'patient_upload',
      handler: (status: unknown): void => {
        this.store.commit('SET_PATIENT_UPLOAD_PROGRESS', status);
      },
    };
  }

  private get presence(): Callback {
    return {
      name: 'presence',
      handler: (): void => {
        this.pingApi.start();
      },
    };
  }

  private get upcomingVisit(): Callback {
    return {
      name: 'patient_upcoming_visit',
      handler: (payload: unknown): void => {
        this.store.dispatch('upcomingVisits/HANDLE_UPCOMING_VISIT_ACTION', payload);
      },
    };
  }

  private get verification(): Callback {
    return {
      name: 'verification',
      handler: (payload: unknown): void => {
        this.store.dispatch('settings/channels/UPDATE_VERIFICATION_STATUS_ACTION', payload, {
          root: true,
        });
      },
    };
  }

  private get task(): Callback {
    return {
      name: 'task',
      handler: (payload: { id: string; events: string[]; task?: Task }) => {
        this.taskCallback.handler(payload);
      },
    };
  }
}
