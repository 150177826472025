import type { ErrorLogger } from '@/modules/phone-widget/domain/webphone/errors/ErrorLogger';
import { injectable } from 'tsyringe';
import { errorTracker } from '@/modules/error-logger/public/api';

@injectable()
export class SentryErrorLogger implements ErrorLogger {
  logAsInfo(error: Error): void {
    errorTracker().logError(error, {
      severity: 'info',
      tags: { module: 'phone-widget', vendor: 'twilio' },
    });
  }

  logAsError(error: Error, contexts: Record<string, any> = {}): void {
    errorTracker().logError(error, {
      contexts,
      tags: { module: 'phone-widget', vendor: 'twilio' },
    });
  }
}
