import type { DoctorFilterOption } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOption';

export class EveryoneOption implements DoctorFilterOption {
  readonly type = 'everyone';

  readonly value: string = 'everyone';

  getName(translationFunction: (key: string) => string): string {
    return translationFunction('task_filter.everyone');
  }

  isMatchingDoctor(): boolean {
    return true;
  }

  static make(): EveryoneOption {
    return new EveryoneOption();
  }
}
