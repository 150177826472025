import { SentryTrackingClientOptions } from '@/modules/error-logger/adapters/client/SentryTrackingClientOptions';
import * as Sentry from '@sentry/vue';
import { singleton } from 'tsyringe';
import { store } from '@/store';
import type { App } from 'vue';
import type { TrackingClient } from '@/modules/error-logger/domain/TrackingClient';

@singleton()
export class SentryTrackingClient implements TrackingClient {
  constructor(private readonly options: SentryTrackingClientOptions) {}

  startTracking(app: App): void {
    this.options.app = app;

    Sentry.init(this.options);
    this.attachStoreWatcher();
  }

  private attachStoreWatcher() {
    store.watch(
      (_, getters) => getters.getUser,
      user => {
        Sentry.setUser(user ? { id: user.id } : null);
      },
    );
  }
}
