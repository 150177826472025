import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { AuthorizationModule } from '@/common/authorization';
import { RootModule } from '@/common/root';
import { HttpClientModule } from '@/modules/http-client';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { RepositoryProvider } from './providers/RepositoryProvider';
import { OverviewProvider } from './providers/OverviewProvider';
import { OutboundTaskProvider } from './providers/OutboundTaskProvider';
import { FilterProvider } from './providers/FiltersProvider';
import { RouterProvider } from './providers/RouterProvider';
import { ChatProvider } from './providers/ChatProvider';
import { OmniStatisticsProvider } from './providers/OmniStatisticsProvider';
import { HelpWidgetModule } from '@/modules/help-widget';

export class StatisticsModule implements DpModule {
  readonly requires = [AuthorizationModule, RootModule, HttpClientModule, HelpWidgetModule];

  providers(): IDpProvider[] {
    return [
      RouterProvider,
      RepositoryProvider,
      AuthorizationProvider,
      OverviewProvider,
      OutboundTaskProvider,
      FilterProvider,
      ChatProvider,
      OmniStatisticsProvider,
    ];
  }
}
