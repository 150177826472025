<template>
  <ul>
    <li v-for="user in users" :key="user.id" :class="$b('item')">
      <avatar-with-status :status="user.status">
        <user-avatar :name="user.name" :src="user.avatar" :tooltip="user.description" />
      </avatar-with-status>
      <span>{{ user.name }}</span>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import AvatarWithStatus from '@/ui/molecules/AvatarWithStatus/AvatarWithStatus.vue';
import UserAvatar from '@/ui/atoms/UserAvatar/UserAvatar.vue';
import type { User } from '@/ui/molecules/UserList/UserList.type';

@Component({
  name: 'UserList',
  components: {
    AvatarWithStatus,
    UserAvatar,
  },
})
export default class UserList extends Vue {
  @Prop({ type: Array, required: true })
  private readonly users: User[];
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables' as v;

.dp-user-list {
  &__item {
    display: flex;
    align-items: center;
    padding: v.$spacer-xs;
    gap: v.$spacer-sm;
  }
}
</style>
