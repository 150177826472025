import { injectable } from 'tsyringe';
import { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import { IsoDate } from '@/modules/timeline/domain/IsoDate';
import { UserOwnerRepository } from '../owner/UserOwnerRepository';
import { StoreMediaRepository } from './StoreMediaRepository';
import type { StoreMessage } from './StoreMessage';

@injectable()
export class StoreWhatsAppMessageFactory {
  constructor(
    private readonly ownerRepository: UserOwnerRepository,
    private readonly mediaRepository: StoreMediaRepository,
  ) {}

  makeMany(messages: StoreMessage[], timezone: string): WhatsAppMessage[] {
    return messages.map(message => this.make(message, timezone));
  }

  private make(message: StoreMessage, timezone: string): WhatsAppMessage {
    return new WhatsAppMessage(
      message.id,
      IsoDate.make(message.created_at, timezone),
      this.mapDirection(message.type),
      message.status ?? 'sent',
      message.body,
      message.owner ? this.ownerRepository.find(message.owner) : null,
      message.media ? this.mediaRepository.getList(message.media) : [],
      message.sid,
    );
  }

  private mapDirection(direction: StoreMessage['type']): WhatsAppMessage['direction'] {
    if (direction === 'outbound-template') {
      return 'outbound';
    }

    return direction;
  }
}
