import { RealTimeCommunicationModule } from '@/common/real-time-communication';
import type { IDpProvider } from '@/core/di/DpProvider';
import type { DpModule } from '@/core/modules/DpModule';
import { TasksListModule } from '@/modules/tasks-list';
import { StoreProvider } from './providers/StoreProvider';
import { ModuleProvider } from './providers/ModuleProvider';
import { UserModule } from '@/modules/user';
import { ErrorLoggerModule } from '@/modules/error-logger';

export class LoggerModule implements DpModule {
  static readonly namespace = Symbol('LoggerModule');

  static readonly requires = [
    RealTimeCommunicationModule,
    UserModule,
    TasksListModule,
    ErrorLoggerModule,
  ];

  providers(): IDpProvider[] {
    return [ModuleProvider, StoreProvider];
  }
}
