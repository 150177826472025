import { phoneApi } from '@/api/phone-api';
import { TASKS_GROUPS } from '@/store/schema/task';
import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { toNamedParams } from '@/utils/vuex';
import { SearchTaskFetcher } from '@/app/task/task-fetch/SearchTaskFetcher';
import { extractPageCursor } from '@/app/task/task-fetch/extract-page-cursor';
import type { DateString } from '@/types/commons';
import { SearchPayloadFactory } from '@/app/search/SearchPayloadFactory';
import { taskMapper } from '@/api/mappers';
import {
  taskCallbackRepository,
  taskCommentRepository,
  taskRepository,
  taskVisitRepository,
} from '@/api/repositories';
import { TaskGroup, TaskTab } from '@/api/types/entities/task';
import type { TaskId } from '@/api/models/Task';
import { Task } from '@/api/models/Task';
import type { EntitiesList } from '@/api/types/entities';
import { StoreTaskRepository } from '@/app/task/OngoingTask/StoreTaskRepository';
import { StoreWorkstationRepository } from '@/app/task/OngoingTask/StoreWorkstationRepository';
import { OngoingTaskRepository } from '@/app/task/OngoingTask/OngoingTaskRepository';
import { TimelineCallRepository } from '@/app/task/OngoingTask/TimelineCallRepository';
import { timelineApi } from '@/modules/timeline/public/api';
import { taskListApi } from '@/modules/tasks-list/public/api';

const searchTaskFetcher = new SearchTaskFetcher(taskRepository);

interface GroupResult {
  tasksIds: Set<TaskId>;
  cursor?: DateString | null;
}

interface State {
  editTaskId?: string | symbol | null;
  groupResults: Record<TaskTab, GroupResult>;
  loadingData: Record<TaskGroup, boolean>;
}

const emptyGroupResult: GroupResult = {
  tasksIds: new Set(),
  cursor: null,
};

interface GroupResultUpdatePayload {
  groupName: string;
  tasksIds: TaskId[];
  cursor: DateString;
}

function isTaskFromCounteredGroup(task: Task, tabName: TaskTab): boolean {
  const api = taskListApi();
  const simplifiedTask = {
    id: task.id,
    group: task.groupName,
    type: task.type,
    ownerId: task.personnel?.id ?? null,
    isAbandoned: !task.isNotAbandoned(),
    patientRequests: task.patient_requests ?? [],
    doctorId: task.doctors[0]?.id ?? null,
  };

  return api.isTaskVisibleForUser(simplifiedTask, tabName);
}

function getInitialGroupResult() {
  return {
    [TaskTab.Open]: { ...emptyGroupResult },
    [TaskTab.Todo]: { ...emptyGroupResult },
    [TaskTab.Prescription]: { ...emptyGroupResult },
    [TaskTab.RefillPrescription]: { ...emptyGroupResult },
    [TaskTab.Certificate]: { ...emptyGroupResult },
    [TaskTab.TestResults]: { ...emptyGroupResult },
    [TaskTab.Other]: { ...emptyGroupResult },
  };
}

const state: State = {
  groupResults: getInitialGroupResult(),
  loadingData: {
    [TaskGroup.Open]: false,
    [TaskGroup.Todo]: false,
    [TaskGroup.All]: false,
  },
};

const getters: GetterTree<State, { entities: EntitiesList<Task> }> = {
  getTaskGroup: toNamedParams(
    ({ getters }) =>
      groupName =>
        Array.from(getters.getTasksIdsForGroup(groupName)),
  ),
  getTasksByIds: toNamedParams(
    ({ rootState }) =>
      ids =>
        taskMapper.denormalizeMany(ids, rootState.entities),
  ),
  getTaskById: (state, getters, rootState) => id =>
    taskMapper.denormalizeSingle(id, rootState.entities),
  getIsLoadingData: state => groupName => state.loadingData[groupName],
  // TODO Move logic to separate class
  getTaskWithOngoingCallForCurrentUser: toNamedParams(({ rootState, rootGetters }) => {
    const user: { id: string } = rootGetters.getUser;

    const tasks = new StoreTaskRepository(rootGetters, new TimelineCallRepository(timelineApi));
    const workstations = new StoreWorkstationRepository(rootGetters);

    const ongoingTaskRepository = new OngoingTaskRepository(tasks, workstations);

    const result = ongoingTaskRepository.get(user);
    if (!result) {
      return null;
    }

    return taskMapper.denormalizeSingle(result.id, rootState.entities);
  }),
  hasTaskWithOngoingCallToCurrentUser: toNamedParams(
    ({ getters }) => getters.getTaskWithOngoingCallForCurrentUser !== null,
  ),
  getOngoingCallForCurrentUser: toNamedParams(
    ({ getters }) => getters.getTaskWithOngoingCallForCurrentUser?.history?.[0],
  ),

  getCursorForGroup: state => groupName => state.groupResults[groupName].cursor ?? null,
  getTasksIdsForGroup: state => groupName => state.groupResults[groupName].tasksIds ?? new Set(),
  getTasksCounterForGroup: state => groupName => state.groupResults[groupName].tasksIds.size ?? 0,

  hasEmptyCounters: (state, getters) =>
    !(
      getters.getTasksCounterForGroup(TASKS_GROUPS.OPEN) ||
      getters.getTasksCounterForGroup(TASKS_GROUPS.TODO)
    ),
};

const mutations: MutationTree<State> = {
  START_LOADING(state, groupName) {
    state.loadingData = {
      ...state.loadingData,
      [groupName]: true,
    };
  },

  END_LOADING(state, groupName) {
    state.loadingData = {
      ...state.loadingData,
      [groupName]: false,
    };
  },

  SET_TASKS_IDS_FOR_GROUP_RESULT(state, { groupName, tasksIds }: GroupResultUpdatePayload) {
    state.groupResults[groupName].tasksIds = tasksIds;
  },

  SET_CURSOR_FOR_GROUP_RESULT(state, { groupName, cursor }: GroupResultUpdatePayload) {
    state.groupResults[groupName].cursor = cursor;
  },

  DELETE_ALL_TASKS_RESULTS(state) {
    state.groupResults = getInitialGroupResult();
  },

  DELETE_TASK_RESULT_FOR_GROUP(state, groupName: TaskTab) {
    state.groupResults[groupName] = { ...emptyGroupResult };
  },
};

const actions: ActionTree<State, unknown> = {
  async FETCH_TASKS_GROUP_ACTION({ dispatch, commit, getters }, groupName: TaskTab) {
    commit('START_LOADING', groupName);

    const { taskIds, cursor } = await taskListApi().getGroupPage(
      groupName,
      getters.getCursorForGroup(groupName),
    );

    if (groupName === TaskTab.Open) {
      commit('SET_TASKS_IDS_FOR_GROUP_RESULT', { groupName, tasksIds: new Set(taskIds) });
    } else {
      dispatch('ADD_IDS_TO_GROUP_RESULT', { groupName, tasksIds: taskIds });
    }

    commit('SET_CURSOR_FOR_GROUP_RESULT', { groupName, cursor: extractPageCursor(cursor ?? null) });

    commit('END_LOADING', groupName);
  },

  async FETCH_SEARCH_TASKS_ACTION({ dispatch, commit, rootGetters }) {
    commit('START_LOADING', TASKS_GROUPS.ALL);
    const searchCriteriaId = rootGetters.getSearchCriteriaId;
    const currentSearchCriteria = rootGetters.getSearchCriteria;
    const searchCursor = rootGetters.getSearchCursor(searchCriteriaId);
    const searchPayload = SearchPayloadFactory.createPayload(currentSearchCriteria);

    const { apiTasks, cursor } = await searchTaskFetcher.create({
      facilityId: rootGetters.getUserFacilityID,
      searchPayload,
      queryParams: {
        before: searchCursor,
      },
    });

    const result = await dispatch('UPDATE_TASK_ACTION', apiTasks);
    await dispatch(
      'SET_SEARCH_RESULT_ACTION',
      { searchCriteriaId, result, cursor: extractPageCursor(cursor) },
      { root: true },
    );
    commit('END_LOADING', TASKS_GROUPS.ALL);
  },

  UPDATE_TASK_ACTION({ commit }, tasks: Task[]) {
    const { entities, result } = taskMapper.normalizeMany(tasks);
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

    return result;
  },

  async FETCH_AND_UPDATE_TASK_ACTION({ commit, getters, dispatch }, taskId: string): Promise<Task> {
    const task = await taskRepository.get(taskId);

    const { entities } = taskMapper.normalizeSingle(task);

    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

    await dispatch('UPDATE_TASK_ID_IN_COUNTERED_GROUPS', task);

    return getters.getTaskById(taskId);
  },

  async UPDATE_TASK_STATUS_ACTION(_, { taskId, resultId }) {
    return taskRepository.setResult(taskId, resultId);
  },

  async ABORT_CALLBACK_ACTION({ dispatch }, taskId) {
    const task = await taskCallbackRepository.abortCallback(taskId);

    dispatch('UPDATE_TASK_ACTION', [task]);
  },

  async MARK_CALL_AS_ENDED_ACTION({ dispatch }, callId) {
    return phoneApi
      .withCall(callId)
      .markAsEnded()
      .then(task => dispatch('UPDATE_TASK_ACTION', [task]));
  },

  async TASK_ARCHIVE_ACTION({ dispatch }, taskId) {
    const task = await taskRepository.archive(taskId);

    dispatch('UPDATE_TASK_ACTION', [task]);
  },

  async TASK_REOPEN_ACTION(_, taskId) {
    await taskRepository.reopen(taskId);
  },

  async VISIT_CANCEL_ACTION({ dispatch }, { id }) {
    const task = await taskVisitRepository.cancelVisit(id);

    dispatch('UPDATE_TASK_ACTION', [task]);
  },

  async VISIT_CONFIRM_ACTION({ dispatch }, { id }) {
    const task = await taskVisitRepository.confirmVisit(id);

    dispatch('UPDATE_TASK_ACTION', [task]);
  },

  async VISIT_RESCHEDULE_ACTION(_, { id }) {
    await taskVisitRepository.rescheduleVisit(id);
  },

  async UPDATE_COMMENT_ACTION(_, { id, comment }) {
    return taskCommentRepository.updateComment(id, comment);
  },

  ADD_IDS_TO_GROUP_RESULT({ commit, getters }, { groupName, tasksIds }: GroupResultUpdatePayload) {
    const currentTasksIds = getters.getTasksIdsForGroup(groupName);

    commit('SET_TASKS_IDS_FOR_GROUP_RESULT', {
      groupName,
      tasksIds: new Set([...currentTasksIds, ...tasksIds]),
    });
  },

  REMOVE_IDS_FROM_GROUP_RESULT(
    { commit, getters },
    { groupName, tasksIds }: GroupResultUpdatePayload,
  ) {
    const currentTasksIds = getters.getTasksIdsForGroup(groupName);

    tasksIds.forEach(id => {
      currentTasksIds.delete(id);
    });

    commit('SET_TASKS_IDS_FOR_GROUP_RESULT', {
      groupName,
      tasksIds: new Set([...currentTasksIds]),
    });
  },

  UPDATE_TASK_IDS_IN_COUNTERED_GROUPS({ commit, getters }, tasks: Task[]) {
    [
      TaskTab.Open,
      TaskTab.Todo,
      TaskTab.Prescription,
      TaskTab.RefillPrescription,
      TaskTab.Certificate,
      TaskTab.TestResults,
      TaskTab.Other,
    ].forEach(tabName => {
      const taskIdsToAdd = tasks
        .filter(task => isTaskFromCounteredGroup(task, tabName))
        .map(({ id }) => id);
      const taskIdsToRemove = tasks
        .filter(task => !taskIdsToAdd.includes(task.id))
        .map(({ id }) => id);

      const currentTasksIds = getters.getTasksIdsForGroup(tabName) as Set<string>;

      const reducedTaskIds = [...currentTasksIds].filter(id => !taskIdsToRemove.includes(id));

      commit('SET_TASKS_IDS_FOR_GROUP_RESULT', {
        groupName: tabName,
        tasksIds: new Set([...reducedTaskIds, ...taskIdsToAdd]),
      });
    });
  },

  UPDATE_TASK_ID_IN_COUNTERED_GROUPS({ dispatch }, task: Task) {
    [
      TaskTab.Open,
      TaskTab.Todo,
      TaskTab.Prescription,
      TaskTab.RefillPrescription,
      TaskTab.Certificate,
      TaskTab.TestResults,
      TaskTab.Other,
    ].forEach(groupName => {
      if (isTaskFromCounteredGroup(task, groupName)) {
        dispatch('ADD_IDS_TO_GROUP_RESULT', { groupName, tasksIds: [task.id] });
      } else {
        dispatch('REMOVE_IDS_FROM_GROUP_RESULT', { groupName, tasksIds: [task.id] });
      }
    });
  },
};

export const task = {
  state,
  getters,
  mutations,
  actions,
};
