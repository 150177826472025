import type { ErrorSender } from '../domain/ErrorSender';
import { errorTracker } from '@/modules/error-logger/public/api';

export class SentryErrorSender implements ErrorSender {
  send(error: Error, contexts: Record<string, Record<string, any>>, fingerprint: string[]): void {
    errorTracker().logError(error, {
      contexts,
      fingerprint,
      severity: 'info',
      tags: { module: 'logger' },
    });
  }
}
