export const WorkingHourOverrideRepositoryToken = Symbol('WorkingHourOverrideRepository');
export const DateFormatterToken = Symbol('DateFormatter');
export const WorkingHoursSiestaEvaluatorToken = Symbol('WorkingHoursSiestaEvaluator');
export const TextChannelRepositoryToken = Symbol('TextChannelRepository');
export const ChannelsCacheToken = Symbol('ChannelsCache');
export const VoiceMessageRepositoryToken = Symbol('VoiceMessageRepository');
export const FileRepositoryToken = Symbol('FileRepository');
export const ConfigurationOptionsListRepositoryToken = Symbol('ConfigurationOptionsListRepository');
export const WorkstationRepositoryToken = Symbol('WorkstationRepository');
export const TrafficControlModeRepositoryToken = Symbol('TrafficControlModeRepository');
export const CallSelectedGroupRepositoryToken = Symbol('CallSelectedGroupRepository');
export const OutboundTaskRepositoryToken = Symbol('OutboundTaskRepository');
export const PolicyValidatorToken = Symbol('PolicyValidator');
export const FacilityNameRepositoryToken = Symbol('FacilityNameRepository');
export const FacilityProfileRepositoryToken = Symbol('FacilityProfileRepository');
export const IntegrationRepositoryToken = Symbol('IntegrationRepository');
export const VisitSynchronizationRepositoryToken = Symbol('VisitSynchronizationRepository');
export const AnonymizationManagerToken = Symbol('AnonymizationManager');
export const CallRecordingStatusRepositoryToken = Symbol('CallRecordingStatusRepository');
export const DocplannerUrlIntegrationRepositoryToken = Symbol(
  'DocplannerUrlIntegrationRepositoryToken',
);
export const CrmRepositoryToken = Symbol('CrmRepository');
export const NotificationManagerToken = Symbol('NotificationManager');
export const TaskAutomationStatusRepositoryToken = Symbol('TaskAutomationStatusRepository');
export const WorkingHourListReadRepositoryToken = Symbol('WorkingHourListReadRepository');
export const WorkingHourListWriteRepositoryToken = Symbol('WorkingHourListWriteRepository');
export const FacilityFetcherToken = Symbol('FacilityFetcher');
export const TermsAndConditionsFactoryToken = Symbol('TermsAndConditionsFactory');

export const MessageTemplateRepositoryToken = Symbol('MessageTemplateRepository');

export const HelpCenterToken = Symbol('HelpCenterController');

export const LocalSettingsRepositoryToken = Symbol('LocalSettingsRepository');
