<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <custom-properties-provider>
        <app-wrapper>
          <router-view />
          <user-modules-actions />
        </app-wrapper>
      </custom-properties-provider>
    </el-config-provider>
    <w-toast :toasts="toasts" target="#app" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref, watch } from 'vue';
import type { Ref } from 'vue';

import { ElNotification as Notification, ElConfigProvider } from 'element-plus';
import { WToast } from '@dp-watson-web/components';
import ElLocaleEn from 'element-plus/dist/locale/en.mjs';
import ElLocalePtBr from 'element-plus/dist/locale/pt-br.mjs';
import ElLocaleEs from 'element-plus/dist/locale/es.mjs';
import ElLocaleIt from 'element-plus/dist/locale/it.mjs';
import ElLocalePl from 'element-plus/dist/locale/pl.mjs';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import UserModulesActions from '@/components/user/UserModulesActions.vue';
import { notificationsManager } from '@/app/notifications/NotificationsManager';
import { resolve } from '@/core/di';
import { EventTrackerFacade } from '@/common/tracking-event/public/api/EventTrackerFacade';
import { eventBus } from '@/core/events';
import { ClientStateChanged } from '@/common/real-time-communication/public/events';
import type { DpListener } from '@/core/events/DpListener';
import CustomPropertiesProvider from '@/ui/scss-variables/custom-properties/CustomPropertiesProvider.vue';
import { getLanguageConfig } from '@/utils/languageDetector';
import { useToaster } from '@/composables/use-toaster';
import type { Language } from './common/root/domain/LanguageConfigProvider';
import AppWrapper from '@/components/AppWrapper.vue';

defineOptions({
  name: 'App',
});

const store = useStore();

const router = useRouter();

const isNetworkOnline = ref(false);

const notification: Ref<null | { close(): void }> = ref(null);

const pusherConnectionState: Ref<null | string> = ref(null);

const eventTracker = computed<EventTrackerFacade>(() => resolve(EventTrackerFacade));

provide('eventTracker', eventTracker);

const isUserLoaded = computed<boolean>(() => store.getters.isUserLoaded);

const locale = computed<Language>(() => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { locale } = getLanguageConfig();

  return (
    {
      en: ElLocaleEn,
      br: ElLocalePtBr,
      es: ElLocaleEs,
      it: ElLocaleIt,
      pl: ElLocalePl,
      mx: ElLocaleEs,
    }[locale] ?? ElLocaleEn
  );
});

const showNetworkError = computed<boolean>(() => {
  if (!pusherConnectionState.value) {
    return !isNetworkOnline.value;
  }

  return (
    !isNetworkOnline.value ||
    ['unavailable', 'failed', 'disconnected'].includes(pusherConnectionState.value)
  );
});

const onPusherStateChanged = (event: ClientStateChanged) => {
  pusherConnectionState.value = event.state.value;
};

const pusherStateListener = computed<DpListener>(() => ({ handle: onPusherStateChanged }));

const startNetworkMonitoring = () => {
  eventBus().subscribe(ClientStateChanged.eventName, pusherStateListener.value);

  isNetworkOnline.value = navigator.onLine;

  window.addEventListener('offline', () => {
    isNetworkOnline.value = false;
  });

  window.addEventListener('online', () => {
    isNetworkOnline.value = true;
  });
};

const closeNotification = () => {
  if (notification.value) {
    notification.value.close();
  }
};

const { t } = useI18n();

const showConnectionError = async () => {
  closeNotification();

  notification.value = await notificationsManager.fire({
    name: 'network',
    title: t('message.network.error_title'),
    message: t('message.network.error_content'),
    onClose: () => {
      notification.value = null;
    },
  });
};

const manageNetworkError = async () => {
  if (isUserLoaded.value && showNetworkError.value) {
    await showConnectionError();
    return;
  }

  closeNotification();
};

const { toasts } = useToaster();

watch(isUserLoaded, manageNetworkError);
watch(showNetworkError, manageNetworkError);

const registerNotifications = () => notificationsManager.registerSubscriber(Notification);

const startViteErrorMonitoring = () => {
  window.addEventListener('vite:preloadError', () => {
    const call = store.getters['phoneWidget:webphone/activeCall'];

    if (call.isInProgress) {
      throw new Error('Vite preload error during the call');
    }

    window.location.reload();
  });
};

onMounted(async () => {
  const html = document.documentElement;
  const { language } = getLanguageConfig();
  html.setAttribute('lang', language);

  type IEDocument = Document & { documentMode: number | undefined };
  const { documentMode } = window.document as IEDocument;

  if (documentMode && documentMode <= 11) {
    await router.push({ name: 'not-supported' });
  }

  startViteErrorMonitoring();
  registerNotifications();
  startNetworkMonitoring();
});
</script>

<style lang="scss">
@forward '@/assets/scss/index.scss';
@use '@/assets/scss/variables' as v;

#app .w-toast {
  --w-toast-bottom-offset: 130px;
}
</style>
