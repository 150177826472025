import { singleton } from 'tsyringe';
import type { Configuration } from './Configuration';
import { RealTimeCommunicationConfigurationError } from './RealTimeCommunicationConfigurationError';

@singleton()
export class ConfigurationService {
  private appKey: string | null = null;
  private cluster: string | null = null;
  private presenceChannelId: string | null = null;
  private privateChannelId: string | null = null;

  setUserData(appKey: string, cluster: string): void {
    this.appKey = appKey;
    this.cluster = cluster;
  }

  setChannels(presenceChannelId: string, privateChannelId: string): void {
    this.presenceChannelId = presenceChannelId;
    this.privateChannelId = privateChannelId;
  }

  clearChannels(): void {
    this.presenceChannelId = null;
    this.privateChannelId = null;
  }

  clearUserData(): void {
    this.appKey = null;
    this.cluster = null;
  }

  getConfiguration(): Configuration | null {
    if (!this.appKey || !this.cluster || !this.presenceChannelId || !this.privateChannelId) {
      throw new RealTimeCommunicationConfigurationError(
        "You're trying to obtain non existing configuration.",
      );
    }

    return {
      appKey: this.appKey,
      cluster: this.cluster,
      presenceChannelId: this.presenceChannelId,
      privateChannelId: this.privateChannelId,
    };
  }
}
