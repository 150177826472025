import { injectable } from 'tsyringe';
import { Orchestrator } from '../../domain/Orchestrator';

@injectable()
export class RealTimeCommunicationEventBinder {
  constructor(private readonly orchestrator: Orchestrator) {}

  bindToPresenceChannel(name: 'workstation_error', callback: (...args: any[]) => any): void {
    this.orchestrator.bindPresenceCallback(name, callback);
  }

  unbindFromPresenceChannel(name: 'workstation_error', callback: (...args: any[]) => any): void {
    this.orchestrator.unbindPresenceCallback(name, callback);
  }
}
