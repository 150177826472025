import { injectable } from 'tsyringe';
import { CurrentFilterOption } from '@/modules/tasks-list/domain/filters/ownership/CurrentFilterOption';
import { DefaultFilterOption } from '@/modules/tasks-list/domain/filters/ownership/DefaultFilterOption';
import { FilterOptionList } from '@/modules/tasks-list/domain/filters/ownership/FilterOptionList';
import type { FilterOption } from '@/modules/tasks-list/domain/filters/ownership/options/FilterOption';
import { FilterOptionListNotFoundError } from '@/modules/tasks-list/domain/filters/ownership/FilterOptionListNotFoundError';

@injectable()
export class OwnershipFilter {
  constructor(
    private readonly currentFilterOption: CurrentFilterOption,
    private readonly defaultFilterOption: DefaultFilterOption,
    private readonly filterOptionList: FilterOptionList,
  ) {}

  getCurrentOption(): FilterOption {
    try {
      return this.currentFilterOption.get();
    } catch (error) {
      if (!(error instanceof FilterOptionListNotFoundError)) {
        throw error;
      }

      const defaultOption = this.defaultFilterOption.forCurrentUser();

      this.currentFilterOption.set(defaultOption.value);

      return defaultOption;
    }
  }

  setCurrentOptionValue(value: string): void {
    this.currentFilterOption.set(value);
  }

  getOptions(): FilterOption[] {
    return this.filterOptionList.getOptions();
  }
}
