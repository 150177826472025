import { inject, injectable } from 'tsyringe';
import { TaskRepositoryToken } from '@/modules/tasks-list/di/tokens';
import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { OwnershipFilter } from '@/modules/tasks-list/domain/filters/ownership/OwnershipFilter';
import { DoctorFilter } from '@/modules/tasks-list/domain/filters/doctor/DoctorFilter';
import type { Task } from '@/modules/tasks-list/domain/real-time-updates/Task';

@injectable()
export class FilteredInboxTaskRepository {
  constructor(
    @inject(TaskRepositoryToken)
    private readonly taskRepository: TaskRepository,
    private readonly ownershipFilter: OwnershipFilter,
    private readonly doctorFilter: DoctorFilter,
  ) {}

  async getFilteredInbox(): Promise<Task[]> {
    return await this.taskRepository.getInboxPage(
      this.ownershipFilter.getCurrentOption(),
      this.doctorFilter.getCurrentOption(),
    );
  }
}
