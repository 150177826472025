import { MessageTemplateStatus } from './MessageTemplateStatus';

export class MessageTemplate {
  constructor(
    public id: string | null,
    public name: string,
    public body: string,
    public status: MessageTemplateStatus = MessageTemplateStatus.Draft,
  ) {}

  get isNewTemplate(): boolean {
    return this.id === null;
  }

  get isDraft(): boolean {
    return this.status === MessageTemplateStatus.Draft;
  }

  get isEditable(): boolean {
    return this.isDraft || this.isNewTemplate;
  }

  get isRejected(): boolean {
    return this.status === MessageTemplateStatus.Rejected;
  }

  get isActive(): boolean {
    return this.status === MessageTemplateStatus.Active;
  }

  get isInReview(): boolean {
    return this.status === MessageTemplateStatus.InReview;
  }

  static empty(): MessageTemplate {
    return new MessageTemplate(null, '', '');
  }
}
