import type { ErrorTracker } from '@/modules/http-client/domain/ErrorTracker';
import { errorTracker } from '@/modules/error-logger/public/api';

export class GlobalErrorTracker implements ErrorTracker {
  logError(error: Error, fingerprint: string[], tags: Record<string, string>): void {
    errorTracker().logError(error, {
      fingerprint,
      tags: { ...tags, module: 'http-client' },
    });
  }
}
