import type { TaskRepository } from '@/modules/tasks-list/domain/task-feed/TaskRepository';
import { injectable } from 'tsyringe';
import { HttpClientApi } from '@/modules/http-client/public/api';
import type { FilterOption } from '@/modules/tasks-list/domain/filters/ownership/options/FilterOption';
import type { Task } from '@/modules/tasks-list/domain/real-time-updates/Task';
import type { DoctorFilterOption } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOption';

@injectable()
export class HttpTaskRepository implements TaskRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  async getInboxPage(
    ownershipFilterOption: FilterOption,
    doctorFilterOption: DoctorFilterOption,
  ): Promise<Task[]> {
    const filters = {};

    if (ownershipFilterOption.type !== 'everyone') {
      filters['ownerId'] = ownershipFilterOption.value;
    }

    if (doctorFilterOption.type !== 'everyone') {
      filters['doctorId'] = doctorFilterOption.value;
    }

    return await this.httpClient.get<any[]>('/api/v0/facility/{facilityId}/tasks/inbox', filters);
  }

  async getTodoPage(): Promise<Task[]> {
    return await this.httpClient.get<any[]>('/api/v0/facility/{facilityId}/tasks/todo');
  }
}
