import type { TaskFeedFetcher } from '@/modules/tasks-list/domain/task-feed/TaskFeedFetcher';
import { TaskFeed } from '@/modules/tasks-list/domain/task-feed/TaskFeed';
import { inject, injectable } from 'tsyringe';
import {
  PatientRequestViewTasksFilterToken,
  TaskEntityManagerToken,
} from '@/modules/tasks-list/di/tokens';
import type { TaskEntityManager } from '@/modules/tasks-list/domain/task-feed/TaskEntityManager';
import type { PatientRequestViewTasksFilter } from '@/modules/tasks-list/domain/task-feed/views/PatientRequestViewTasksFilter';
import { FilteredInboxTaskRepository } from '@/modules/tasks-list/domain/task-feed/FilteredInboxTaskRepository';

@injectable()
export class CertificateTaskFeedFetcher implements TaskFeedFetcher {
  constructor(
    @inject(TaskEntityManagerToken)
    private readonly taskEntityManager: TaskEntityManager,
    @inject(PatientRequestViewTasksFilterToken)
    private readonly patientRequestViewTasksFilter: PatientRequestViewTasksFilter,
    private readonly filteredInboxTaskRepository: FilteredInboxTaskRepository,
  ) {}

  async fetch(): Promise<TaskFeed> {
    const tasks = await this.filteredInboxTaskRepository.getFilteredInbox();

    const prescriptionTasks = this.patientRequestViewTasksFilter.byType(tasks, 'certificate');

    const ids = await this.taskEntityManager.storeEntities(prescriptionTasks);

    return new TaskFeed(ids);
  }
}
