import { UserApi } from '@/modules/user/public/api/UserApi';
import { injectable } from 'tsyringe';
import { Owner } from '../../../../domain/owner/Owner';
import type { OwnerRepository } from '../../../../domain/owner/OwnerRepository';

@injectable()
export class UserOwnerRepository implements OwnerRepository {
  constructor(private readonly userApi: UserApi) {}

  find(ownerId: string): Owner | null {
    const user = this.userApi.getById(ownerId);

    if (user.id === '') {
      if (ownerId.length > 0) {
        return Owner.makeRemovedUser(ownerId);
      }

      return null;
    }

    return new Owner(user.id, user.name, user.avatar ?? '');
  }

  getMe(): Owner {
    const user = this.userApi.getCurrentUser();

    return new Owner(user.id, user.name, user.avatar ?? '');
  }
}
