<template>
  <div>
    <app-status-downtime v-if="hasStatusBanner" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

import AppStatusDowntime from '@/components/app-status/AppStatusDowntime.vue';

@Component({
  name: 'AppStatus',
  components: {
    AppStatusDowntime,
  },
})
export default class AppStatus extends Vue {
  get hasStatusBanner(): boolean {
    return this.$store.getters.isAppNotOperational;
  }
}
</script>
