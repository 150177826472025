import type { TextChannel } from './channel/TextChannel';
import type { MessageTemplateRepository } from './templates/MessageTemplateRepository';
import type { MessageTemplate } from './templates/MessageTemplate';
import type { ConversationRepository } from './ConversationRepository';
import type { PhoneNumber } from './PhoneNumber';
import type { ConversationId } from './ConversationId';

export class MessageComposer {
  constructor(
    private readonly channels: TextChannel[],
    private readonly phoneNumber: PhoneNumber,
    private readonly conversationId: ConversationId,
  ) {}

  get defaultChannel(): TextChannel {
    return this.channels[0];
  }

  get canSendAnyMessages(): boolean {
    return this.channels.length > 0;
  }

  get canSendVoiceRecordings(): boolean {
    return this.channels.some((channel: TextChannel) => channel.canSendVoiceRecordings);
  }

  canSendFreeFormMessages(): boolean {
    return this.defaultChannel.canSendFreeFormMessages;
  }

  hasToInitializeWithTemplate(): boolean {
    return this.defaultChannel.hasToInitializeWithTemplate;
  }

  canSendAttachments(): boolean {
    return this.defaultChannel.canSendAttachments;
  }

  get messageLengthLimit(): number {
    return this.defaultChannel.messageLengthLimit;
  }

  async getTextMessageTemplates(
    textMessageTemplateRepository: MessageTemplateRepository,
  ): Promise<MessageTemplate[]> {
    return textMessageTemplateRepository.get();
  }

  async sendTemplateTextMessage(
    conversationRepository: ConversationRepository,
    template: MessageTemplate,
  ): Promise<void> {
    return conversationRepository.addTemplateMessage(this.phoneNumber, template);
  }

  async sendFreeFormTextMessage(body: string, mediaUrl?: string): Promise<void> {
    return this.defaultChannel.sendMessage(this.conversationId, this.phoneNumber, body, mediaUrl);
  }
}
