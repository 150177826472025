import { inject, singleton } from 'tsyringe';
import { TaskUpdatedEventPublisherToken } from '@/modules/tasks-list/di/tokens';
import type { Task } from '../Task';
import { TaskUpdater } from '../TaskUpdater';
import { PayloadStorage } from '../queue/PayloadStorage';
import { Queue } from '../queue/Queue';
import { AllQueueRunner } from '../queue/AllQueueRunner';
import type { TaskUpdatedEventPublisher } from '../../events/TaskUpdatedEventPublisher';
import { TaskUpdatedFactory } from '../../events/TaskUpdatedFactory';
import { QueueConsumer } from '../queue/QueueConsumer';

/**
 * It's a controller for a queue. Contains API that should be used to schedule processing data.
 * It accepts new requests to process pusher data and supervises the process:
 *   - activates necessary trackers
 *   - stores extra data obtained with pusher request
 *   - defines callback {@link QueueHandler} that should be execute for each collection of items, here all items are processed at once
 *   - emits domain event {@link TaskUpdatedEvent} for each processed item with valid extra data obtained from pusher
 */
@singleton()
export class TaskQueueSupervisor {
  private readonly delayInMs = 1000;

  private readonly queue: Queue = new Queue();

  private readonly queueConsumer: QueueConsumer;

  constructor(
    private readonly taskUpdater: TaskUpdater,
    private readonly payloadStorage: PayloadStorage<Task>,
    @inject(TaskUpdatedEventPublisherToken)
    private readonly taskUpdatedEventPublisher: TaskUpdatedEventPublisher,
    private readonly taskUpdatedFactory: TaskUpdatedFactory,
  ) {
    const allQueueRunner = new AllQueueRunner(this.handleAll.bind(this));
    this.queueConsumer = new QueueConsumer(allQueueRunner, this.queue, this.delayInMs);
  }

  addToQueue(id: string, task: Task, updateTriggers: string[]): void {
    this.queue.enqueueOrUpdate(id);
    this.payloadStorage.merge(id, updateTriggers);
    this.payloadStorage.setOrReplace(id, task);
    this.queueConsumer.consume();
  }

  reset(): void {
    this.queueConsumer.reset();
    this.queue.reset();
    this.payloadStorage.reset();
  }

  private async handleAll(ids: string[]): Promise<void> {
    const storedTasks = ids
      .map(id => this.payloadStorage.pop(id))
      .filter((storedTask: Task | undefined): storedTask is Task => !!storedTask);

    await this.taskUpdater.updateMany(storedTasks);

    storedTasks.forEach(storedTask => {
      this.taskUpdatedEventPublisher.publish(
        this.taskUpdatedFactory.make(storedTask, this.payloadStorage.popAccumulated(storedTask.id)),
      );
    });
  }
}
