import { injectable } from 'tsyringe';
import type { MessageTemplateRepository } from '@/modules/settings/domain/message-templates/MessageTemplateRepository';
import type { InputMessageTemplate } from './types/InputMessageTemplate';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { MessageTemplate } from '@/modules/settings/domain/message-templates/MessageTemplate';
import { MessageTemplateFactory } from './MessageTemplateFactory';

@injectable()
export class HttpMessageTemplateRepository implements MessageTemplateRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly messageTemplateFactory: MessageTemplateFactory,
  ) {}

  async get(): Promise<MessageTemplate[]> {
    const templates = await this.httpClient.get<InputMessageTemplate[]>(
      `/api/v0/facility/{facilityId}/message/template`,
    );

    if (!templates.length) {
      return [];
    }

    return this.messageTemplateFactory.makeMany(templates);
  }

  async create(template: MessageTemplate): Promise<MessageTemplate> {
    const response = await this.httpClient.post<InputMessageTemplate[]>(
      '/api/v0/facility/{facilityId}/message/template',
      this.messageTemplateFactory.parseToBackend(template),
    );

    return this.messageTemplateFactory.makeMany(response)[0];
  }

  async edit(template: MessageTemplate): Promise<MessageTemplate> {
    const response = await this.httpClient.patch<InputMessageTemplate[]>(
      `/api/v0/facility/{facilityId}/message/template/${template.id}`,
      this.messageTemplateFactory.parseToBackend(template),
    );

    return this.messageTemplateFactory.makeMany(response)[0];
  }

  async delete(templateId: string): Promise<void> {
    await this.httpClient.delete(`/api/v0/facility/{facilityId}/message/template/${templateId}`);
  }
}
