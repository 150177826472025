import type { Module } from 'vuex';

interface User {
  id: string;
}

interface PusherState {
  activeUsers: User[];
}

export const storeName = 'pusher';

export const pusher: Module<PusherState, unknown> = {
  namespaced: true,
  state: () => ({
    activeUsers: [],
  }),
  getters: {
    getActiveUsersIds: state => state.activeUsers.map(({ id }) => id) || [],
  },
  mutations: {
    SET_ACTIVE_USERS(state, data = []) {
      state.activeUsers = data;
    },
  },
};
