import { getUnixTime, parseISO, isPast, fromUnixTime } from 'date-fns';
import { userStatus } from '@/app/user-statuses';
import { mapObject, pipe } from '@/utils/functions';
import { userSessionApi } from '@/modules/user/public/api';
import { storeName } from '@/common/real-time-communication/ui/store/pusher.ts';

const moduleName = 'userStatusManager';

function normalizeCurrent(currentObject) {
  return {
    status: currentObject.is_online ? userStatus.ONLINE : userStatus.PAUSE,
    endDate: getUnixTime(parseISO(currentObject.end_date || '')),
  };
}

function checkIfOffline(status) {
  const sessionExpired = isPast(fromUnixTime(status.endDate));
  if (sessionExpired) {
    return {
      ...status,
      status: userStatus.OFFLINE,
    };
  }

  return status;
}

const pauseManagerModule = {
  state: {
    apiUsersStatuses: {},
  },

  getters: {
    getCurrentUserStatus: (state, getters, rootState, rootGetters) =>
      getters.getUsersStatuses[String(rootGetters.getUser.id)],

    getUserStatus: (_, getters) => id => getters.getUsersStatuses[id],

    getUsersStatuses: (state, getters, rootState, rootGetters) =>
      mapObject(
        pipe(
          ({ status, ...user }) => ({
            ...user,
            status: rootGetters[`${storeName}/getActiveUsersIds`].includes(user.id)
              ? status
              : userStatus.OFFLINE,
          }),
          checkIfOffline,
        ),
      )(state.apiUsersStatuses),

    getPauseEndTime: (_, getters) => {
      const currentUserStatus = getters.getCurrentUserStatus;

      if (!currentUserStatus) {
        return null;
      }

      const { status, endDate } = currentUserStatus;

      return status === userStatus.PAUSE ? endDate : null;
    },

    isPauseActive: (_, getters) => !!getters.getPauseEndTime,
  },

  mutations: {
    SET_USERS_STATUSES(state, payload) {
      state.apiUsersStatuses = {
        ...state.apiUsersStatuses,
        ...payload,
      };
    },
  },

  actions: {
    async SET_PAUSE_TIME_ACTION({ commit, state, rootGetters }, payload) {
      if (typeof payload !== 'number' || !Number.isSafeInteger(payload)) {
        throw new Error('Invalid time format. Provide unix time.');
      }

      const data = await userSessionApi().breakStart(`@${payload}`);
      const { id } = rootGetters.getUser;

      commit('SET_USERS_STATUSES', {
        [id]: {
          ...state.apiUsersStatuses[id],
          ...normalizeCurrent(data),
        },
      });

      await userSessionApi().saveSession(data.start_date, data.is_online);
    },

    async CANCEL_PAUSE_TIME_ACTION({ commit, state, rootGetters }) {
      const data = await userSessionApi().breakEnd();

      if (!data) {
        await userSessionApi().saveSession(null, false);
        return;
      }

      const { id } = rootGetters.getUser;

      commit('SET_USERS_STATUSES', {
        [id]: {
          ...state.apiUsersStatuses[id],
          ...normalizeCurrent(data),
        },
      });

      await userSessionApi().saveSession(data.start_date, data.is_online);
    },
  },
};

export const usersStatusManagerPlugin = store => {
  store.registerModule(moduleName, pauseManagerModule);

  // Start/stop clock for active pause
  store.watch(
    (_, getters) => getters.isPauseActive,
    val => {
      if (val) {
        store.dispatch('START_TIMER', `${moduleName}.pause`);
      }

      store.dispatch('STOP_TIMER', `${moduleName}.pause`);
    },
  );

  let unwatchPause = null;
  store.watch(
    (_, getters) => getters.getPauseEndTime,
    pauseEndTime => {
      if (pauseEndTime && !unwatchPause) {
        unwatchPause = store.watch(
          (_, getters) => getters.getCurrentTimestamp,
          currentTimestamp => {
            if (currentTimestamp >= pauseEndTime) {
              store.dispatch('CANCEL_PAUSE_TIME_ACTION');
            }
          },
        );
        return;
      }

      if (typeof unwatchPause === 'function') {
        unwatchPause();
        unwatchPause = null;
      }
    },
  );
};
