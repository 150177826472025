<template>
  <div :class="$b()">
    <header :class="$b('header')">
      <div :class="$b('logo')">
        <slot name="logo" />
      </div>
    </header>

    <main :class="$b('main')">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'FullPageWrapper',
})
export default class FullPageWrapper extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/functions' as f;
@use 'src/assets/scss/mixins' as m;

.dp-full-page-wrapper {
  position: relative;

  &__header {
    position: relative;
    z-index: 1;
    width: auto;
    padding: v.$spacer-md;
    background: rgba(0, 0, 0, 0);
  }

  &__logo {
    width: f.px-to-rem(180px);
    height: v.$full-page-logo-height;
  }

  @include m.media(md) {
    &__header {
      position: fixed;
      padding: v.$spacer-lg;
    }

    &__main {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      overflow: hidden;
    }
  }
}
</style>
