import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ChatControllerToken } from '../tokens';
import type { ChatController } from '../../domain/chat/ChatController';
import { BackwardsCompatibleSalesForceChatController } from '@/modules/help-widget/adapters/chat/BackwardsCompatibleSalesForceChatController';

export class ChatProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.registerSingleton<ChatController>(
      ChatControllerToken,
      BackwardsCompatibleSalesForceChatController,
    );
  }
}
