import type { DpEvent } from '@/core/events/DpEvent';
import { injectable } from 'tsyringe';
import type { ChannelConfiguration } from './ChannelConfiguration';

@injectable()
export class ChannelConfigurationChangedEvent implements DpEvent {
  static readonly eventName = 'RealTimeCommunication:ChannelConfigurationChanged';

  readonly name = ChannelConfigurationChangedEvent.eventName;

  constructor(type: 'added', configuration: ChannelConfiguration);
  constructor(type: 'changed', configuration: ChannelConfiguration);
  constructor(type: 'removed', configuration: null);
  constructor(
    readonly type: 'added' | 'changed' | 'removed',
    readonly configuration: ChannelConfiguration | null,
  ) {}
}
