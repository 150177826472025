import type { Contexts, ErrorLogger } from '@/common/real-time-communication/domain/ErrorLogger';
import { errorTracker } from '@/modules/error-logger/public/api';
import { injectable } from 'tsyringe';

@injectable()
export class GlobalErrorLogger implements ErrorLogger {
  logInfoWithContext(error: Error, contexts: Contexts, fingerprint: string[]): void {
    errorTracker().logError(error, {
      contexts,
      fingerprint,
      tags: { module: 'real-time-communication' },
      severity: 'info',
    });
  }
}
