import type { DpProvider } from '@/core/di/DpProvider';
import { errorTracker } from '@/modules/error-logger/public/api';
import { delay, registry } from 'tsyringe';
import { GlobalErrorTracker } from '@/modules/http-client/adapters/GlobalErrorTracker';
import { ErrorTrackerToken } from '@/modules/http-client/di/tokens';

@registry([
  {
    token: ErrorTrackerToken,
    useToken: delay(() => GlobalErrorTracker),
  },
])
export class ErrorTrackingProvider implements DpProvider {
  boot(): void {
    const tracker = errorTracker();

    tracker.ignoreError(/^Network Error$/);
    tracker.ignoreError(
      /^HTTP request "GET: \/api\/v0\/facility\/(.*)\/booking\/get-doctors" failed with status code "400"$/,
    );
  }
}
