import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { Authorization } from '@/common/authorization/domain/Authorization';
import {
  AccessSettings,
  AccessStatistics,
  UseEventLogger,
  ChangeWorkstation,
} from '@/modules/dashboard/domain/policies';
import { AuthorizerToken } from '@/modules/dashboard/di/token';
import type { Authorizer } from '@/modules/dashboard/domain/Authorizer';
import { AuthorizationApiAuthorizer } from '@/modules/dashboard/adapters/AuthorizationApiAuthorizer';

export class AuthorizationProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<Authorizer>(AuthorizerToken, AuthorizationApiAuthorizer);
  }

  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: AccessStatistics,
        handler: user =>
          (user.hasOpenGate('RECEPTION_STATS_ACCESS') || user.isManager()) &&
          user.hasFeature('STATISTICS'),
      },
      {
        name: AccessSettings,
        handler: user => user.hasFeature('FACILITY_SETTINGS'),
      },
      {
        name: ChangeWorkstation,
        handler: user => user.hasFeature('WORKSTATION_LOGIN'),
      },
      {
        name: UseEventLogger,
        handler: user => user.hasOpenGate('DEVELOPER_MODE'),
      },
    ]);
  }
}
