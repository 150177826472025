import { injectable } from 'tsyringe';
import type { ErrorLogger } from '@/modules/error-logger/domain/logging/ErrorLogger';
import { SentryErrorLogger } from '@/modules/error-logger/adapters/logger/SentryErrorLogger';
import { ConsoleErrorLogger } from '@/modules/error-logger/adapters/logger/ConsoleErrorLogger';
import type { ErrorOptions } from '@/modules/error-logger/domain/ErrorOptions';

@injectable()
export class CombinedErrorLogger implements ErrorLogger {
  constructor(
    private readonly sentryErrorLogger: SentryErrorLogger,
    private readonly consoleErrorLogger: ConsoleErrorLogger,
  ) {}

  logException(error: Error, options?: ErrorOptions): void {
    if (this.sentryErrorLogger.isInitialized()) {
      this.sentryErrorLogger.logException(error, options);
    } else {
      this.consoleErrorLogger.logException(error, options);
    }
  }
}
