<template>
  <div :class="$b({ 'with-app-status-message': hasStatusBanner })">
    <app-status />
    <slot :has-status-banner="hasStatusBanner" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

import AppStatus from '@/components/app-status/AppStatus.vue';

@Component({
  name: 'AppWrapper',
  components: { AppStatus },
})
export default class AppStatusDowntime extends Vue {
  get hasStatusBanner(): boolean {
    return this.$store.getters.isAppNotOperational;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-app-wrapper {
  display: block;
  position: relative;

  @media print {
    padding-top: 0;
  }

  &--with-app-status-message {
    padding-top: v.$app-status-message;
  }
}
</style>
