import type { DateString, UrlAddress, UUID } from '@/api/types';
import type { Call } from '@/api/types/entities/call';
import type { UpcomingVisit } from '@/api/types/entities/upcoming-visit';
import type { User } from '@/api/types/entities/user';
import type { Context } from '@/api/types/entities/context';
import type { Result } from '@/api/types/entities/result';
import type { FormMessage } from '@/api/types/entities/form-message';
import type { ChannelName } from '@/api/types/entities/channel';
import type { Conversation } from './conversation';

export enum TaskTab {
  Open = 'open',
  Todo = 'todo',
  Prescription = 'prescription',
  RefillPrescription = 'refill-prescription',
  Certificate = 'certificate',
  TestResults = 'test-results',
  Other = 'other',
}

export enum TaskGroup {
  Open = 'open',
  Todo = 'todo',
  All = 'all',
}

export enum TaskType {
  InboundCall = 'inbound-call',
  InboundForm = 'inbound-form',
  TextConversation = 'text-conversation',
  InboundNonReception = 'inbound-non-reception',
  OutboundVisitConfirmation = 'outbound-visit-confirmation',
  OutboundCall = 'outbound-call',
}

export enum TaskStatus {
  Open = 'open',
  Resolved = 'resolved',
  VisitConfirmationSuccessful = 'visit-confirmation-successful',
  OnlineBookingConversion = 'online-booking-conversion',
  Abandoned = 'abandoned',
  Expired = 'expired',
  Archived = 'archived',
}

export enum OutboundVisitConfirmationTaskStatus {
  New = 'new',
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Rescheduled = 'rescheduled',
}

export interface VisitConfirmation {
  status: OutboundVisitConfirmationTaskStatus;
  payload: {
    startDateTime: DateString;
    serviceName: string;
    doctor: {
      firstName: string;
      lastName: string;
      title: string;
    };
    links: {
      patientDetail: UrlAddress;
    };
  };
}

export interface TaskVoicemail {
  sent: boolean;
  converted: boolean;
}

export interface TaskVoiceMenu {
  label: string;
  priority: number;
}

export type TaskId = UUID;

export interface BaseTask {
  anonymous: boolean;
  caller: string;
  comment: string;
  context?: Context;
  conversation?: Conversation;
  created_at: DateString;
  expires_at: DateString;
  history: Call[];
  id: TaskId;
  most_recent_call_at: DateString;
  patient: { id: string };
  patient_upcoming_visit?: UpcomingVisit;
  personnel?: User;
  doctors?: { id: string }[];
  phone: ChannelName;
  starred: boolean;
  result?: Result;
  form_message?: FormMessage;
  visit_confirmation?: VisitConfirmation;
  status: TaskStatus;
  tab: TaskTab;
  updated_at: DateString;
  voicemail: TaskVoicemail;
  voice_menu?: TaskVoiceMenu;
  reminder_at?: DateString;
  patient_requests?: any[];
  is_dp_chat_inbound_communication_allowed?: boolean;
}

interface InboundCallTask extends BaseTask {
  type: TaskType.InboundCall;
}

interface InboundFormTask extends BaseTask {
  type: TaskType.InboundForm;
  form_message: FormMessage;
}

interface InboundTextTask extends BaseTask {
  type: TaskType.TextConversation;
  conversation: Conversation;
}

interface OutboundVisitConfirmationTask extends BaseTask {
  type: TaskType.OutboundVisitConfirmation;
  visit_confirmation: VisitConfirmation;
}

interface OutboundCallTask extends BaseTask {
  type: TaskType.OutboundCall;
}

interface InboundNonReception extends BaseTask {
  type: TaskType.InboundNonReception;
}

export type Task =
  | InboundCallTask
  | InboundFormTask
  | InboundTextTask
  | InboundNonReception
  | OutboundVisitConfirmationTask
  | OutboundCallTask;
