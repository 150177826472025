import { inject, singleton } from 'tsyringe';
import { ClientReconnectService } from '../ClientReconnectService';
import { ClientManagerToken } from '../../di/tokens';
import type { ClientManager } from '../client/ClientManager';
import { DpEventBus } from '@/core/events/DpEventBus';
import { ChannelConfigurationChangedEvent } from '../configuration/ChannelConfigurationChangedEvent';
import type { DpListener } from '@/core/events/DpListener';
import { FacilityLoadedEvent, FacilityUnloadedEvent } from '@/modules/facility/public/events';
import { PingEvent } from '@/modules/user/public/events';
import { ConnectionFailureListener } from './ConnectionFailureListener';
import { ClientStateChanged } from '../events/ClientStateChanged';
import { Orchestrator } from '../Orchestrator';
import { UserNotificationListener } from './UserNotificationListener';

@singleton()
export class ConnectionObserver {
  private isObserving = false;

  constructor(
    private readonly clientReconnectService: ClientReconnectService,
    @inject(ClientManagerToken)
    private readonly clientManager: ClientManager,
    private readonly eventBus: DpEventBus,
    private readonly connectionFailureListener: ConnectionFailureListener,
    private readonly orchestrator: Orchestrator,
    private readonly userNotificationListener: UserNotificationListener,
  ) {}

  init(): void {
    this.eventBus.subscribe(FacilityLoadedEvent.eventName, this.facilityLoadedListener);
    this.eventBus.subscribe(FacilityUnloadedEvent.eventName, this.facilityUnloadedListener);
  }

  private triggerReconnectionCheck(): void {
    if (!this.isObserving) {
      return;
    }

    if (this.clientManager.state === 'connected') {
      this.clientReconnectService.finish();
      return;
    }

    this.clientReconnectService.initialize();
  }

  private readonly visibilityChangeListener = () => {
    const isVisible = document.visibilityState === 'visible';

    if (isVisible) {
      this.triggerReconnectionCheck();
    }
  };

  private readonly pingListener: DpListener = {
    handle: () => {
      this.triggerReconnectionCheck();
    },
  };

  private readonly configurationListener: DpListener = {
    handle: async (event: ChannelConfigurationChangedEvent) => {
      if (event.type === 'changed') {
        await this.orchestrator.update();
      }
    },
  };

  private readonly facilityLoadedListener: DpListener = {
    handle: async () => {
      await this.orchestrator.create();

      document.addEventListener('visibilitychange', this.visibilityChangeListener);

      this.eventBus.subscribe(
        ChannelConfigurationChangedEvent.eventName,
        this.configurationListener,
      );
      this.eventBus.subscribe(PingEvent.eventName, this.pingListener);
      this.eventBus.subscribe(ClientStateChanged.eventName, this.connectionFailureListener);
      this.eventBus.subscribe(ClientStateChanged.eventName, this.userNotificationListener);

      this.isObserving = true;

      this.triggerReconnectionCheck();
    },
  };

  private readonly facilityUnloadedListener: DpListener = {
    handle: () => {
      this.isObserving = false;
      document.removeEventListener('visibilitychange', this.visibilityChangeListener);
      this.eventBus.unsubscribe(
        ChannelConfigurationChangedEvent.eventName,
        this.configurationListener,
      );
      this.eventBus.unsubscribe(ClientStateChanged.eventName, this.connectionFailureListener);
      this.eventBus.unsubscribe(ClientStateChanged.eventName, this.userNotificationListener);
      this.eventBus.unsubscribe(PingEvent.eventName, this.pingListener);

      this.orchestrator.disconnect();
    },
  };
}
