import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { OmniChannelStatisticsRepositoryToken } from '../tokens';
import type { OmniChannelStatisticsRepository } from '../../domain/omni-statistics/OmniChannelStatisticsRepository';
import { HttpOmniChannelStatisticsRepository } from '../../adapters/omni-statistics/HttpOmniChannelStatisticsRepository';

export class OmniStatisticsProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<OmniChannelStatisticsRepository>(
      OmniChannelStatisticsRepositoryToken,
      HttpOmniChannelStatisticsRepository,
    );
  }
}
