import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { AuthorizationModule } from '@/common/authorization';
import { HttpClientModule } from '@/modules/http-client';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { AdapterProvider } from './providers/AdapterProvider';
import { WorkingHoursProvider } from './providers/WorkingHoursProvider';
import { ChannelsProvider } from './providers/ChannelsProvider';
import { CacheProvider } from './providers/CacheProvider';
import { TrafficControlProvider } from './providers/TrafficControlProvider';
import { StoreBootProvider } from './providers/StoreBootProvider';
import { OutboundTaskProvider } from './providers/OutboundTaskProvider';
import { FacilityIntegrationProvider } from './providers/FacilityIntegration';
import { AnonymizationProvider } from './providers/AnonymizationProvider';
import { CallRecordingProvider } from './providers/CallRecordingProvider';
import { SuperAdminProvider } from './providers/SuperAdminProvider';
import { TaskAutomationProvider } from './providers/TaskAutomationProvider';
import { WorkstationProvider } from './providers/WorkstationProvider';
import { TermsAndConditionsProvider } from './providers/TermsAndConditionsProvider';
import { PermissionsProvider } from './providers/PermissionsProvider';
import { MessageTemplatesProvider } from './providers/MessageTemplatesProvider';
import { HelpCenterProvider } from './providers/HelpCenterProvider';
import { ConfigurationProvider } from './providers/ConfigurationProvider';
import { ErrorTrackingProvider } from '@/modules/settings/di/providers/ErrorTrackingProvider';

export class SettingsModule implements DpModule {
  readonly requires = [RootModule, AuthorizationModule, HttpClientModule];

  providers(): IDpProvider[] {
    return [
      CacheProvider,
      AdapterProvider,
      StoreBootProvider,
      TrafficControlProvider,
      AuthorizationProvider,
      WorkingHoursProvider,
      ChannelsProvider,
      OutboundTaskProvider,
      FacilityIntegrationProvider,
      AnonymizationProvider,
      CallRecordingProvider,
      SuperAdminProvider,
      TaskAutomationProvider,
      WorkstationProvider,
      TermsAndConditionsProvider,
      PermissionsProvider,
      MessageTemplatesProvider,
      HelpCenterProvider,
      ConfigurationProvider,
      ErrorTrackingProvider,
    ];
  }
}
