import { inject, singleton } from 'tsyringe';
import { RestartExecutor } from './RestartExecutor';
import { PendingCallError } from './errors/PendingCallError';
import { RestartFailError } from './errors/RestartFailError';
import { RestartTimer } from './RestartTimer';
import type { ErrorLogger } from '@/modules/phone-widget/domain/webphone/errors/ErrorLogger';
import { ErrorLoggerToken } from '@/modules/phone-widget/di/tokens';

type Callback = () => void;

@singleton()
export class RestartScheduler {
  private isWaitingForReset = false;

  private restartCallback: Callback;

  private numberOfRestartAttempts = 0;

  constructor(
    private readonly restartExecutor: RestartExecutor,
    private readonly restartTimer: RestartTimer,
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
  ) {}

  setRestartCallback(callback: Callback): void {
    this.restartCallback = callback;
  }

  tryRestart(): void {
    if (this.restartTimer.isRunning()) {
      this.tryRestartLater();
      return;
    }

    this.reset();
  }

  forceAwaitingReset(): void {
    if (!this.isWaitingForReset) {
      return;
    }

    this.reset();
  }

  private reset() {
    this.numberOfRestartAttempts += 1;
    this.restartTimer.start();

    try {
      this.restartExecutor.execute(this.restartCallback);

      this.isWaitingForReset = false;
      this.restartTimer.resetPeriod();
      this.numberOfRestartAttempts = 0;
    } catch (error) {
      if (error instanceof RestartFailError) {
        this.logError(error);
        this.restartTimer.increasePeriod();
        this.tryRestartLater();
        return;
      }

      if (error instanceof PendingCallError) {
        this.logError(error);
        this.tryRestartAsSoonAsPossible();
        return;
      }

      throw error;
    }
  }

  private tryRestartLater() {
    this.isWaitingForReset = true;

    setTimeout(this.reset.bind(this), this.restartTimer.getTimeLeft());
  }

  private tryRestartAsSoonAsPossible() {
    this.isWaitingForReset = true;
  }

  private logError(error: Error) {
    this.errorLogger.logAsError(error, {
      resetConditions: {
        numberOfRestartAttempts: this.numberOfRestartAttempts,
      },
    });
  }
}
