import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { PatientInboundChatAccess } from '../../domain/patient/PatientInboundChatAccess';
import { PatientInboundChatAccessToken } from '../tokens';
import { HttpPatientInboundChatAccess } from '../../adapters/patient/HttpPatientInboundChatAccess';

export class PatientProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<PatientInboundChatAccess>(
      PatientInboundChatAccessToken,
      HttpPatientInboundChatAccess,
    );
  }
}
