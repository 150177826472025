import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root';
import { ObserverProvider } from './provider/ObserverProvider';
import { TrackingProvider } from './provider/TrackingProvider';
import { HttpApiProvider } from './provider/HttpApiProvider';
import { ErrorTrackingProvider } from '@/modules/facility/di/provider/ErrorTrackingProvider';

export class FacilityModule implements DpModule {
  static readonly requires = [RootModule];

  providers(): IDpProvider[] {
    return [HttpApiProvider, TrackingProvider, ObserverProvider, ErrorTrackingProvider];
  }
}
