export const FacilityTimeZoneToken = Symbol('FacilityTimeZoneToken');
export const DateUtilsToken = Symbol('DateUtilsToken');
export const DateConverterToken = Symbol('DateConverter');
export const TextChannelRepositoryToken = Symbol('TextChannelRepository');
export const CachedStateFactoryToken = Symbol('CachedStateFactory');
export const OmniTaskSwitcherAuthorizerToken = Symbol('OmniTaskSwitcherAuthorizer');
export const UserRepositoryToken = Symbol('UserRepository');
export const OwnerRepositoryToken = Symbol('OwnerRepository');
export const FilterCurrentStateRepositoryToken = Symbol('FilterCurrentStateRepository');
export const TaskRepositoryToken = Symbol('TaskFeedRepository');
export const TaskCounterRepositoryToken = Symbol('TaskCounterRepository');
export const MediaRepositoryToken = Symbol('MediaRepository');
export const TemplatePathRepositoryToken = Symbol('TemplatePathRepository');
export const PatientRepositoryToken = Symbol('PatientRepository');
export const EventTrackerToken = Symbol('EventTracker');
export const TaskCreationRepositoryToken = Symbol('TaskCreationRepository');
export const TaskReadRepositoryToken = Symbol('TaskReadRepository');
export const TaskNormalizerToken = Symbol('TaskNormalizer');
export const TaskWriteRepositoryToken = Symbol('TaskRepository');
export const TaskFactoryToken = Symbol('TaskFactory');
export const LocaleProviderToken = Symbol('LocaleProvider');
export const TaskUpdatedEventPublisherToken = Symbol('TaskUpdatedEventPublisher');
export const AuthorizerToken = Symbol('Authorizer');
export const TaskEntityManagerToken = Symbol('TaskEntityManager');
export const PatientRequestViewTasksFilterToken = Symbol('PatientRequestViewTasksFilter');
export const DoctorRepositoryToken = Symbol('DoctorRepository');
