import { injectable } from 'tsyringe';
import type { ErrorEvent, EventHint, Integration, Options } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import { router } from '@/router';
import { store } from '@/store';
import { ErrorDecorationCollection } from '@/modules/error-logger/domain/decoration/ErrorDecorationCollection';
import { ErrorIgnoringCollection } from '@/modules/error-logger/domain/ignoring/ErrorIgnoringCollection';
import type { ErrorOptions } from '@/modules/error-logger/domain/ErrorOptions';
import type { App } from 'vue';

@injectable()
export class SentryTrackingClientOptions implements Options {
  readonly dsn = 'https://d6bfe40bf33942cbabe55838aebb5226@o130295.ingest.sentry.io/5534902';

  readonly integrations: Integration[] = [];

  readonly tracesSampleRate = 0.05;

  readonly trackComponents = false;

  readonly environment = 'production';

  readonly debug = false;

  readonly transport = Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport);

  readonly release = import.meta.env.VITE_APP_COMMIT_SHA || 'local-release';

  app: App;

  constructor(
    private readonly decorationCollection: ErrorDecorationCollection,
    private readonly ignoringCollection: ErrorIgnoringCollection,
  ) {
    this.integrations.push(Sentry.browserTracingIntegration({ router, routeLabel: 'path' }));
    this.integrations.push(
      Sentry.thirdPartyErrorFilterIntegration({
        // App key from vite plugin config: vite.config.mts
        filterKeys: ['docplanner-frontend/phone-front-app'],
        behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
      }),
    );
  }

  beforeSend = (event: ErrorEvent, hint: EventHint): ErrorEvent | null => {
    const originalException = hint.originalException;
    const decorators = this.decorationCollection.get();
    const ignoredErrorRegexes = this.ignoringCollection.get();

    if (!(originalException instanceof Error)) {
      return event;
    }

    for (const ignoredErrorRegex of ignoredErrorRegexes) {
      if (ignoredErrorRegex.test(originalException.message)) {
        return null;
      }
    }

    for (const decorator of decorators) {
      if (decorator.matchingFunction(originalException)) {
        event = this.applyDecorationOptions(event, decorator.decoratorFunction(originalException));
      }
    }

    return event;
  };

  tracesSampler = (): number => {
    if (store.getters.getUser.id === '396') {
      return 0;
    }

    return this.tracesSampleRate;
  };

  private applyDecorationOptions(event: ErrorEvent, options: ErrorOptions): ErrorEvent {
    if (options.contexts) {
      event.contexts = { ...event.contexts, ...options.contexts };
    }

    if (options.fingerprint) {
      event.fingerprint = options.fingerprint;
    }

    if (options.severity) {
      event.level = options.severity;
    }

    if (options.tags) {
      event.tags = { ...event.tags, ...options.tags };
    }

    return event;
  }
}
