import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { DpEventBus } from '@/core/events/DpEventBus';
import { LogoutEvent } from '@/modules/authentication/public/events';
import { FilterResetListener } from '@/modules/tasks-list/domain/filters/ownership/FilterResetListener';
import { TaskUpdatedListener } from '@/modules/tasks-list/domain/outbound-calls-tracker/TaskUpdatedListener';
import { TaskUpdatedEvent } from '@/modules/tasks-list/domain/events/TaskUpdatedEvent';
import type { TaskUpdatedEventPublisher } from '../../domain/events/TaskUpdatedEventPublisher';
import { TaskUpdatedEventPublisherToken } from '../tokens';
import { EventBusTaskUpdatedEventPublisher } from '../../adapters/events/EventBusTaskUpdatedEventPublisher';

@injectable()
export class EventProvider implements DpProvider {
  constructor(
    private readonly eventBus: DpEventBus,
    private readonly filterResetListener: FilterResetListener,
    private readonly taskUpdatedListener: TaskUpdatedListener,
  ) {}

  register(container: DependencyContainer): void {
    container.register<TaskUpdatedEventPublisher>(
      TaskUpdatedEventPublisherToken,
      EventBusTaskUpdatedEventPublisher,
    );
  }

  boot(): void {
    this.eventBus.subscribe(LogoutEvent.eventName, this.filterResetListener);
    this.eventBus.subscribe(TaskUpdatedEvent.eventName, this.taskUpdatedListener);
  }
}
