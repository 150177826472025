import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { ErrorLoggerToken } from '@/modules/book-visit/di/token';
import type { ErrorLogger } from '@/modules/book-visit/domain/errors/ErrorLogger';
import { GlobalErrorLogger } from '@/modules/book-visit/adapters/errors/GlobalErrorLogger';

export class ErrorLoggerProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ErrorLogger>(ErrorLoggerToken, GlobalErrorLogger);
  }
}
