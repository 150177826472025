import { singleton } from 'tsyringe';
import type { ChatController } from '@/modules/help-widget/domain/chat/ChatController';
import { ChatError } from '@/modules/help-widget/domain/chat/ChatError';

@singleton()
export class SalesForceChatController implements ChatController {
  private hasAttachedListeners = false;

  private destroyCallback: (() => void) | null = null;

  async show(): Promise<void> {
    this.init();

    if (!this.salesForceChat) {
      throw new ChatError('The chat is not available.');
    }

    try {
      await this.salesForceChat?.utilAPI.launchChat();
    } catch {
      throw new ChatError('Failed to launch the chat.');
    }
  }

  onDestroyed(callback: (() => void) | null): void {
    if (callback !== null && this.destroyCallback) {
      throw new ChatError('You cannot set a destroy callback more than once.');
    }

    this.destroyCallback = callback;
  }

  private get salesForceChat(): typeof window.embeddedservice_bootstrap {
    return window.embeddedservice_bootstrap;
  }

  private init() {
    if (!this.salesForceChat || this.hasAttachedListeners) {
      return;
    }

    this.attachListeners();
    this.hasAttachedListeners = true;
  }

  private attachListeners() {
    if (this.salesForceChat) {
      this.salesForceChat.onClose = async () => {
        this.destroyCallback?.();
        this.destroyCallback = null;
      };
    }
  }
}
