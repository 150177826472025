import type { DpListener } from '@/core/events/DpListener';
import { inject, injectable } from 'tsyringe';
import type { ClientStateChanged } from '../events/ClientStateChanged';
import { UserNotifierToken } from '../../di/tokens';
import type { UserNotifier } from '../UserNotifier';

@injectable()
export class UserNotificationListener implements DpListener {
  constructor(
    @inject(UserNotifierToken)
    private readonly userNotifier: UserNotifier,
  ) {}

  handle(event: ClientStateChanged): void {
    const { state } = event;

    if (state.isIssueVisibleForUser) {
      this.userNotifier.clientUnauthorized();
      return;
    }

    this.userNotifier.closeNotification();
  }
}
