export class HttpClientError<T = Record<string, unknown>> extends Error {
  constructor(
    message: string,
    public readonly statusCode: number | undefined,
    public readonly data: T,
    public readonly url: string,
    public readonly method: string,
  ) {
    if (statusCode && method && url) {
      message = `HTTP request "${method.toUpperCase()}: ${url}" failed with status code "${statusCode}"`;
    }

    super(message);

    // Changing prototype for extending default class is recommended by TS
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, HttpClientError.prototype);

    this.name = 'HttpClientError';
  }

  get statusCodeString(): string {
    return this.statusCode ? this.statusCode.toString() : 'Unknown';
  }

  get normalizedUrl(): string {
    const uuidRegex = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const idRegex = /\/\d{1,20}/g;
    const tokenRegex = /\/reset-password\/([\w-]+)/g;
    const phoneNumberRegex = /\/\+\d{9,13}/g;
    const ulidRegex = /\/[0-9A-HJKMNP-TV-Z]{26}/g;

    return this.url
      .replace(tokenRegex, '/reset-password/:token')
      .replace(phoneNumberRegex, '/:phoneNumber')
      .replace(uuidRegex, '/:uuid')
      .replace(ulidRegex, '/:ulid')
      .replace(idRegex, '/:id');
  }
}
