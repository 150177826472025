<template>
  <ul :class="$b()">
    <li v-if="hasMoreUsers" :class="$b('item', { more: true })" :style="{ zIndex: counterZIndex }">
      <dp-popover :class="$b('popover')" placement="bottom" trigger="click" :offset="5">
        <dp-circle color="primary-light" class="position-relative">
          +{{ numberOfHiddenUsers }}
        </dp-circle>
        <template #popover-content>
          <user-list :users="hiddenUsers" />
        </template>
      </dp-popover>
    </li>
    <li v-for="{ user, zIndex } in slots" :key="user.id" :class="$b('item')" :style="{ zIndex }">
      <avatar-with-status :status="user.status">
        <user-avatar :name="user.name" :src="user.avatar" :tooltip="user.description" />
      </avatar-with-status>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpCircle, DpPopover } from '@dp-ui-kit/vue';

import type { Status } from '@/ui/molecules/AvatarWithStatus/AvatarWithStatus.types';
import AvatarWithStatus from '@/ui/molecules/AvatarWithStatus/AvatarWithStatus.vue';
import UserAvatar from '@/ui/atoms/UserAvatar/UserAvatar.vue';
import UserList from '@/ui/molecules/UserList/UserList.vue';

interface User {
  id: string;
  name: string;
  avatar: string | null;
  status: Status;
  description: string;
}

interface Slot {
  zIndex: number;
  user: User;
}

@Component({
  name: 'AvatarPile',
  components: { UserList, UserAvatar, AvatarWithStatus, DpCircle, DpPopover },
})
export default class AvatarPile extends Vue {
  @Prop({ type: Array, required: true })
  private readonly users: User[];

  @Prop({ type: Number, required: true })
  private readonly limit: number;

  get hasMoreUsers(): boolean {
    return !!this.numberOfHiddenUsers;
  }

  get numberOfHiddenUsers(): number {
    return this.hiddenUsers.length;
  }

  get slots(): Slot[] {
    return this.visibleUsers.map((user, index, { length }) => ({ user, zIndex: length - index }));
  }

  get counterZIndex(): number {
    return this.visibleUsers.length + 1;
  }

  get visibleUsers(): User[] {
    if (this.users.length === this.limit) {
      return this.users;
    }

    return this.users.slice(0, this.limit - 1);
  }

  get hiddenUsers(): User[] {
    if (this.users.length === this.limit) {
      return [];
    }

    return this.users.slice(this.limit - 1);
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$avatar-border-overlap: 3px;

.dp-avatar-pile {
  display: flex;
  padding: 0;
  list-style-type: none;
  line-height: 0;

  &__item {
    position: relative;
    margin-left: -5px;

    &--more {
      cursor: pointer;
      z-index: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: -#{$avatar-border-overlap};
      left: -#{$avatar-border-overlap};
      display: block;
      width: calc(100% + #{$avatar-border-overlap * 2});
      height: calc(100% + #{$avatar-border-overlap * 2});
      border-radius: 50%;
      background: v.$white;
    }
  }

  &__popover {
    :deep(.popover-body) {
      padding: 0;
    }
  }
}
</style>
