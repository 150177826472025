/* eslint-disable no-console */

import type { ErrorLogger } from '../../domain/logging/ErrorLogger';
import { injectable } from 'tsyringe';
import type { ErrorOptions } from '@/modules/error-logger/domain/ErrorOptions';

@injectable()
export class ConsoleErrorLogger implements ErrorLogger {
  logException(error: Error, options?: ErrorOptions): void {
    console.groupCollapsed();

    console.error('logException: error :>> ', error);

    if (options?.contexts) {
      console.error('logException: contexts :>> ', options.contexts);
    }

    if (options?.fingerprint) {
      console.error('logException: fingerprint :>> ', options.fingerprint);
    }

    if (options?.severity) {
      console.error('logException: severity :>> ', options.severity);
    }

    if (options?.tags) {
      console.error('logException: tags :>> ', options.tags);
    }

    console.groupEnd();
  }
}
