import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { SentryTrackingClient } from '@/modules/error-logger/adapters/client/SentryTrackingClient';
import type { TrackingClient } from '@/modules/error-logger/domain/TrackingClient';
import { TrackingClientToken } from '@/modules/error-logger/di/tokens';

export class TrackerClientProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<TrackingClient>(TrackingClientToken, SentryTrackingClient);
  }
}
