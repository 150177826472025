import { inject, injectable } from 'tsyringe';
import type { Client } from './client/Client';
import { CallbackFactoryToken, ClientManagerToken } from '../di/tokens';
import type { ClientManager } from './client/ClientManager';
import { RealTimeCommunicationError } from './RealTimeCommunicationError';
import type { CallbackFactory } from './CallbackFactory';
import type { Configuration } from './configuration/Configuration';
import type { PresenceChannel } from './client/PresenceChannel';
import { ConfigurationService } from './configuration/ConfigurationService';

@injectable()
export class Orchestrator {
  constructor(
    @inject(ClientManagerToken)
    private readonly clientManager: ClientManager,
    private readonly configurationService: ConfigurationService,
    @inject(CallbackFactoryToken)
    private readonly callbackFactory: CallbackFactory,
  ) {}

  async create(): Promise<void> {
    const configuration = this.getConfiguration();
    const client = await this.clientManager.make(configuration);

    this.bindCallbacks(client, configuration.presenceChannelId, configuration.privateChannelId);
  }

  async update(): Promise<void> {
    const configuration = this.getConfiguration();
    const client = await this.clientManager.make(configuration);

    this.bindCallbacks(client, configuration.presenceChannelId, configuration.privateChannelId);
  }

  disconnect(): void {
    this.callbackFactory.cleanUpCallbacks();
    this.clientManager.disconnect();
  }

  bindPresenceCallback(name: 'workstation_error', callback: (...args: any[]) => any): void {
    const configuration = this.getConfiguration();
    const current = this.clientManager.getCurrent();
    const presenceChannel = current.channel(configuration.presenceChannelId);

    presenceChannel.bind(name, callback);
  }

  unbindPresenceCallback(name: 'workstation_error', callback: (...args: any[]) => any): void {
    const configuration = this.getConfiguration();
    const privateChannel = this.clientManager.getCurrent().channel(configuration.privateChannelId);

    privateChannel.unbind(name, callback);
  }

  private bindCallbacks(client: Client, presenceChannelId: string, privateChannelId: string): void {
    const presenceChannel = client.channel(presenceChannelId);
    for (const callback of this.callbackFactory.makeForPresenceChannel(
      presenceChannel as PresenceChannel,
    )) {
      presenceChannel.bind(callback.name, callback.handler);
    }

    const privateChannel = client.channel(privateChannelId);
    for (const callback of this.callbackFactory.makeForPrivateChannel()) {
      privateChannel.bind(callback.name, callback.handler);
    }
  }

  private getConfiguration(): Configuration {
    const configuration = this.configurationService.getConfiguration();

    if (!configuration) {
      throw new RealTimeCommunicationError(
        "Cannot create pusher client. There's missing configuration.",
      );
    }

    return configuration;
  }
}
