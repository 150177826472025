import * as Sentry from '@sentry/vue';

import type { ErrorLogger } from '../../domain/logging/ErrorLogger';
import { injectable } from 'tsyringe';
import type { ErrorOptions } from '@/modules/error-logger/domain/ErrorOptions';

@injectable()
export class SentryErrorLogger implements ErrorLogger {
  isInitialized(): boolean {
    return Sentry.getClient() !== undefined;
  }

  logException(error: Error, options?: ErrorOptions): void {
    Sentry.withScope(scope => {
      if (options?.contexts) {
        Object.entries(options.contexts).forEach(([contextName, context]) => {
          scope.setContext(contextName, context);
        });
      }

      if (options?.fingerprint) {
        scope.setFingerprint(options.fingerprint);
      }

      if (options?.severity) {
        scope.setLevel(options.severity);
      }

      if (options?.tags) {
        scope.setTags(options.tags);
      }

      Sentry.captureException(error);
    });
  }
}
