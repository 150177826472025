import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { LoggerProvide } from '@/modules/error-logger/di/providers/LoggerProvide';
import { TrackerClientProvider } from '@/modules/error-logger/di/providers/TrackerClientProvider';
import { ErrorTrackingProvider } from '@/modules/error-logger/di/providers/ErrorTrackingProvider';

export class ErrorLoggerModule implements DpModule {
  providers(): IDpProvider[] {
    return [TrackerClientProvider, LoggerProvide, ErrorTrackingProvider];
  }
}
