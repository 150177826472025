import { MessageTemplateStatus } from '@/modules/settings/domain/message-templates/MessageTemplateStatus';
import { BackendMessageTemplateStatus } from './types/BackendMessageTemplateStatus';

const toBackendStatusMapping: Record<MessageTemplateStatus, BackendMessageTemplateStatus> = {
  [MessageTemplateStatus.Draft]: BackendMessageTemplateStatus.Draft,
  [MessageTemplateStatus.Active]: BackendMessageTemplateStatus.Active,
  [MessageTemplateStatus.Rejected]: BackendMessageTemplateStatus.Rejected,
  [MessageTemplateStatus.InReview]: BackendMessageTemplateStatus.InReview,
};

const toDomainStatusMapping: Record<BackendMessageTemplateStatus, MessageTemplateStatus> = {
  [BackendMessageTemplateStatus.Draft]: MessageTemplateStatus.Draft,
  [BackendMessageTemplateStatus.Active]: MessageTemplateStatus.Active,
  [BackendMessageTemplateStatus.Rejected]: MessageTemplateStatus.Rejected,
  [BackendMessageTemplateStatus.InReview]: MessageTemplateStatus.InReview,
};

export class MessageTemplateStatusMapper {
  static toBackend(status: MessageTemplateStatus): BackendMessageTemplateStatus {
    if (!(status in toBackendStatusMapping)) {
      throw new Error(`Unknown message template status: ${status}`);
    }

    return toBackendStatusMapping[status];
  }

  static toDomain(status: BackendMessageTemplateStatus): MessageTemplateStatus {
    if (!(status in toDomainStatusMapping)) {
      throw new Error(`Unknown backend message template status (backend payload): ${status}`);
    }

    return toDomainStatusMapping[status];
  }
}
