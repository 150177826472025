import 'reflect-metadata';
import '@/core';
import '@/app/settings/validation-rules';
import { createApp } from 'vue';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import { ElLoading as Loading } from 'element-plus';
import errorHandler from '@/mixins/global/error-handler';
import { phraseAppPlugin } from '@/ui/plugins/phrase-app-trans';
import { safePushPlugin } from '@/ui/plugins/router-safe-push';
import { appStatusPlugin } from '@/ui/plugins/app-status-supervisor';
import { formattersPlugin } from '@/ui/plugins/formatters';
import { CustomPropertiesPlugin } from '@/ui/scss-variables/custom-properties';
import { focus } from '@/ui/directives/focus';
import { gtmPlugin } from '@/ui/plugins/gtm';
import { store } from './store';
import { safeTranslatePlugin, i18n } from './ui/plugins/i18n';
import { plugin as bemPlugin } from './ui/plugins/bem';
import { sanitizeHtmlDirective } from './directives/sanitized-html';
import App from './App.vue';
import './assets/tailwindcss/index.pcss';
import { getLanguageConfig } from './utils/languageDetector';
import { trackingClient } from '@/modules/error-logger/public/api';

(async () => {
  const app = createApp(App);

  const { router } = await import(`./router/index`);

  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(safeTranslatePlugin)
    .use(phraseAppPlugin)
    .use(VuePlyr)
    .use(appStatusPlugin, { store })
    .use(safePushPlugin, { router })
    .use(bemPlugin)
    .use(Loading)
    .use(formattersPlugin)
    .use(CustomPropertiesPlugin)
    .use(gtmPlugin)
    .directive('sanitize-html', sanitizeHtmlDirective)
    .directive('focus', focus)
    .mixin(errorHandler);

  const { locale } = getLanguageConfig();

  const translations = await import(`./translations/${locale}.json`);
  i18n.global.setLocaleMessage(locale, translations.default);

  app.config.performance = import.meta.env.DEV;

  if (document.getElementById('app')) {
    if (import.meta.env.PROD || Boolean(import.meta.env.VITE_APP_SENTRY_ACTIVE)) {
      trackingClient().startTracking(app);
    }

    app.mount('#app');
  }
})();
