export const IntegrationRepositoryToken = Symbol('IntegrationRepositoryToken');

export const CallbacksDataRepositoryToken = Symbol('CallbacksDataRepository');
export const ChannelsDataRepositoryToken = Symbol('ChannelsDataRepository');
export const ConfirmationCallsDataRepositoryToken = Symbol('ConfirmationCallsDataRepository');
export const QualityOfServiceDataRepositoryToken = Symbol('QualityOfServiceDataRepository');
export const SubjectsDataRepositoryToken = Symbol('SubjectsDataRepository');
export const TeamDataRepositoryToken = Symbol('TeamDataRepository');
export const TrafficDataRepositoryToken = Symbol('TrafficDataRepository');
export const OutboundTaskSwitcherRepositoryToken = Symbol('OutboundTaskSwitcherRepository');
export const PolicyValidatorToken = Symbol('PolicyValidator');
export const InboundAttendanceRepositoryToken = Symbol('InboundAttendanceRepository');
export const DateRangeRepositoryToken = Symbol('DateRangeRepository');
export const OutboundAttendanceRepositoryToken = Symbol('OutboundAttendanceRepository');
export const PerformanceDataRepositoryToken = Symbol('PerformanceDataRepository');
export const TotalTasksRepositoryToken = Symbol('TotalTasksRepository');
export const CostPerVisitRepositoryToken = Symbol('CostPerVisitRepository');
export const CallbackRepositoryToken = Symbol('CallbackRepository');
export const FacilityCountryCodeRepositoryToken = Symbol('FacilityCountryCodeRepository');
export const ChatControllerToken = Symbol('ChatController');
export const TextChannelEvaluatorToken = Symbol('TextChannelEvaluator');

export const BookingRepositoryToken = Symbol('BookingRepository');
export const OmniChannelStatisticsRepositoryToken = Symbol('OmniChannelStatisticsRepository');
export const FeedbackFormToken = Symbol('FeedbackForm');
