import { injectable } from 'tsyringe';
import { Chat } from '@/modules/help-widget/domain/chat/Chat';
import { Feedback } from '../../domain/feedback-form/Feedback';

@injectable()
export class HelpCenterApi {
  constructor(
    private readonly chat: Chat,
    private readonly feedback: Feedback,
  ) {}

  async openChat(): Promise<void> {
    return this.chat.show();
  }

  async openFeedbackForm(): Promise<void> {
    (await this.feedback.make()).open();
  }
}
