import { AnonymizationModule } from './anonymization';
import { AuthenticationModule } from './authentication';
import { BookVisitModule } from './book-visit';
import { DashboardModule } from './dashboard';
import { PhoneWidgetModule } from './phone-widget';
import { SettingsModule } from './settings';
import { SettingsCallRecordingModule } from './settings-call-recording';
import { SettingsCallsManagementModule } from './settings-calls-management';
import { SettingsDataExportsModule } from './settings-data-exports';
import { StatisticsModule } from './statistics';
import { TasksListModule } from './tasks-list';
import { UserModule } from './user';
import { FacilityModule } from './facility';
import { WorkstationModule } from './workstation';
import { PatientModule } from './patient';
import { TimelineModule } from './timeline';
import { TaskModule } from './task';
import { NotificationModule } from './notification';
import { HttpClientModule } from './http-client';
import { HelpWidgetModule } from './help-widget';
import { LoggerModule } from './logger';
import { ErrorLoggerModule } from './error-logger';

import { AppConfigModule } from '../common/app-config';
import { AuthorizationModule } from '../common/authorization';
import { RootModule } from '../common/root';
import { RouterModule } from '../common/legacy/router';
import { TrackingEventModule } from '../common/tracking-event';
import { RealTimeCommunicationModule } from '../common/real-time-communication';

export const modules = [
  AnonymizationModule,
  AppConfigModule,
  AuthenticationModule,
  AuthorizationModule,
  BookVisitModule,
  DashboardModule,
  ErrorLoggerModule,
  FacilityModule,
  HttpClientModule,
  PatientModule,
  PhoneWidgetModule,
  RealTimeCommunicationModule,
  RootModule,
  RouterModule,
  SettingsCallRecordingModule,
  SettingsCallsManagementModule,
  SettingsDataExportsModule,
  SettingsModule,
  StatisticsModule,
  TaskModule,
  TasksListModule,
  TimelineModule,
  TrackingEventModule,
  UserModule,
  WorkstationModule,
  NotificationModule,
  HelpWidgetModule,
  LoggerModule,
];
