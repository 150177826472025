export class Owner {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly avatarSrc: string,
    readonly customIcon: 'user' | null = null,
  ) {}

  static makeRemovedUser(id: string): Owner {
    return new Owner(id, '', '', 'user');
  }
}
