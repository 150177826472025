import { inject, injectable } from 'tsyringe';
import type { ErrorLogger } from '@/modules/error-logger/domain/logging/ErrorLogger';
import type { ErrorMatchingFunction } from '@/modules/error-logger/domain/decoration/ErrorMatchingFunction';
import type { ErrorOptions } from '@/modules/error-logger/domain/ErrorOptions';
import { ErrorDecorationCollection } from '@/modules/error-logger/domain/decoration/ErrorDecorationCollection';
import { ErrorLoggerToken } from '@/modules/error-logger/di/tokens';
import { ErrorIgnoringCollection } from '@/modules/error-logger/domain/ignoring/ErrorIgnoringCollection';
import type { ErrorDecoratorFunction } from '@/modules/error-logger/domain/decoration/ErrorDecoratorFunction';

@injectable()
export class ErrorTracker {
  constructor(
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
    private readonly errorDecoratorManager: ErrorDecorationCollection,
    private readonly errorIgnoringManager: ErrorIgnoringCollection,
  ) {}

  logError(error: Error, options?: ErrorOptions): void {
    this.errorLogger.logException(error, options);
  }

  catchError(errorMatching: ErrorMatchingFunction, errorDecorator: ErrorDecoratorFunction): void {
    this.errorDecoratorManager.add(errorMatching, errorDecorator);
  }

  catchAllErrors(errorDecorator: ErrorDecoratorFunction): void {
    this.errorDecoratorManager.add(() => true, errorDecorator);
  }

  ignoreError(matchingRegex: RegExp): void {
    this.errorIgnoringManager.add(matchingRegex);
  }
}
