import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { HelpCenterController } from '@/modules/settings/domain/HelpCenterController';
import { HelpCenterApi } from '@/modules/help-widget/public/api';
import { HelpCenterToken } from '@/modules/settings/di/token';

export class HelpCenterProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<HelpCenterController>(HelpCenterToken, HelpCenterApi);
  }
}
