import { inject, injectable } from 'tsyringe';
import type { Trackable } from '../tracking/Trackable';
import {
  CurrentFacilityRepositoryToken,
  CurrentUserRepositoryToken,
  EventSenderToken,
  FormControllerToken,
  LocalizationRepositoryToken,
} from '../../di/tokens';
import type { FormController } from './FormController';
import type { CurrentFacilityRepository } from './CurrentFacilityRepository';
import type { CurrentUserRepository } from './CurrentUserRepository';
import type { LocalizationRepository } from '../LocalizationRepository';
import type { FormApi } from './FormApi';
import type { EventSender } from '../tracking/EventSender';
import { ItemSelectedEvent } from '../tracking/ItemSelectedEvent';

@injectable()
export class Feedback implements Trackable {
  constructor(
    @inject(LocalizationRepositoryToken)
    private readonly localizationRepository: LocalizationRepository,
    @inject(CurrentUserRepositoryToken)
    private readonly currentUserRepository: CurrentUserRepository,
    @inject(CurrentFacilityRepositoryToken)
    private readonly currentFacilityRepository: CurrentFacilityRepository,
    @inject(FormControllerToken)
    private readonly formController: FormController,
    @inject(EventSenderToken)
    private readonly eventSender: EventSender,
  ) {}

  async make(): Promise<FormApi> {
    const user = await this.currentUserRepository.get();
    const facility = await this.currentFacilityRepository.get();
    const localization = this.localizationRepository.get();

    return this.formController.make(localization, {
      name: user.name,
      email: user.email,
      phone_number: user.phoneNumber,
      role: user.role,
      facility: facility.name,
    });
  }

  async track(): Promise<void> {
    const businessEvent = ItemSelectedEvent.make('Feedback');
    return businessEvent.track(this.eventSender);
  }
}
