import type { ChatController } from '@/modules/help-widget/domain/chat/ChatController';
import { LegacySalesForceChatController } from '@/modules/help-widget/adapters/chat/LegacySalesForceChatController';
import { SalesForceChatController } from '@/modules/help-widget/adapters/chat/SalesForceChatController';
import { injectable } from 'tsyringe';

@injectable()
export class BackwardsCompatibleSalesForceChatController implements ChatController {
  constructor(
    private readonly legacySalesForceChatController: LegacySalesForceChatController,
    private readonly salesForceChatController: SalesForceChatController,
  ) {}

  async show(): Promise<void> {
    if (window.embeddedservice_bootstrap?.utilAPI) {
      await this.salesForceChatController.show();
    } else {
      await this.legacySalesForceChatController.show();
    }
  }
  onDestroyed(callback: (() => void) | null): void {
    if (window.embeddedservice_bootstrap?.utilAPI) {
      this.salesForceChatController.onDestroyed(callback);
    } else {
      this.legacySalesForceChatController.onDestroyed(callback);
    }
  }
}
