import { injectable } from 'tsyringe';
import type { OmniChannelStatisticsRepository } from '../../domain/omni-statistics/OmniChannelStatisticsRepository';
import type { OmniChannelStatistics } from '../../domain/omni-statistics/types/OmniChannelStatistics';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { OmniChannelStatisticsFactory } from './OmniChannelStatisticsFactory';
import type { InputOmniChannelStatistics } from './InputOmniChannelStatistics';

@injectable()
export class HttpOmniChannelStatisticsRepository implements OmniChannelStatisticsRepository {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly omniChannelStatsFactory: OmniChannelStatisticsFactory,
  ) {}

  async get(start: string, end: string): Promise<OmniChannelStatistics> {
    const inputStats: InputOmniChannelStatistics = await this.httpClient.get(
      '/api/v0/facility/{facilityId}/stats/all',
      {
        start,
        end,
      },
    );

    return this.omniChannelStatsFactory.make(inputStats);
  }
}
