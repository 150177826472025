import { injectable } from 'tsyringe';
import type { OmniChannelStatistics } from '../../domain/omni-statistics/types/OmniChannelStatistics';
import type { InputOmniChannelStatistics } from './InputOmniChannelStatistics';

@injectable()
export class OmniChannelStatisticsFactory {
  make(inputStats: InputOmniChannelStatistics): OmniChannelStatistics {
    const inbound = {
      notAttempted: inputStats.inbound.not_attempted,
      successful: inputStats.inbound.successful,
      unsuccessful: inputStats.inbound.unsuccessful,
    };

    const outbound = {
      successful: inputStats.outbound.successful,
      unsuccessful: inputStats.outbound.unsuccessful,
    };

    const channels = inputStats.channels.map(channel => ({
      type: channel.type,
      interactions: channel.interactions,
      startedTasks: channel.startedTasks ?? channel.started_tasks,
    }));

    const allTasks = inputStats.traffic.reduce((acc, record) => acc + record.all, 0);

    return {
      booking: inputStats.booking.tasks,
      inbound,
      outbound,
      channels,
      traffic: inputStats.traffic,
      allTasks,
    };
  }
}
