import type { NormalizedSchema } from 'normalizr';

export enum EntityTypes {
  Task = 'task',
  Call = 'call',
  Context = 'context',
  Media = 'media',
  Patient = 'patient',
  Phone = 'phone',
  PhoneVerification = 'phoneVerification',
  Result = 'result',
  User = 'user',
  Facility = 'facility',
  Permission = 'permission',
  Form = 'form',
  FormMessage = 'formMessage',
  Workstation = 'receptionPhone',
  WorkstationError = 'workstationError',
  ComplianceBundle = 'complianceBundle',
  DataExport = 'dataExport',
  UpcomingVisit = 'upcomingVisit',
  UpcomingVisitOutput = 'upcomingVisitOutput',
  Conversation = 'conversation',
  ConversationMessage = 'conversationMessage',
}

type EntitiesKeys = keyof typeof EntityTypes;

export type Entities = (typeof EntityTypes)[EntitiesKeys];

export type EntitiesList<T> = Partial<Record<Entities, Record<string, T>>>;

export type NormalizerResult<T> = NormalizedSchema<EntitiesList<T>, string | number>;
