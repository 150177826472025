import { inject, injectable } from 'tsyringe';
import { CachedStateFactoryToken } from '@/modules/tasks-list/di/tokens';
import type { StorageItem, CachedStateFactory } from '@/modules/tasks-list/adapters/Storage';
import type { FilterCurrentStateRepository } from '@/modules/tasks-list/domain/filters/ownership/state/FilterCurrentStateRepository';
import { FilterCurrentState } from '@/modules/tasks-list/domain/filters/ownership/state/FilterCurrentState';

@injectable()
export class StorageFilterCurrentStateRepository implements FilterCurrentStateRepository {
  private readonly cachedState: StorageItem<string>;

  constructor(@inject(CachedStateFactoryToken) cachedStateFactory: CachedStateFactory<string>) {
    this.cachedState = cachedStateFactory('TaskOwnershipFilter');
  }

  get(): FilterCurrentState {
    const { value } = this.cachedState;

    return new FilterCurrentState(value ?? '');
  }

  set(state: FilterCurrentState): void {
    this.cachedState.set(state.value);
  }

  clear(): void {
    this.cachedState.clear();
  }
}
