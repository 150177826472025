import { EveryoneOption } from '@/modules/tasks-list/domain/filters/doctor/options/types/EveryoneOption';
import { inject, injectable } from 'tsyringe';
import type { DoctorRepository } from '@/modules/tasks-list/domain/filters/doctor/doctors/DoctorRepository';
import { DoctorOption } from '@/modules/tasks-list/domain/filters/doctor/options/types/DoctorOption';
import type { DoctorFilterOption } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOption';
import { DoctorFilterOptionListNotFoundError } from '@/modules/tasks-list/domain/filters/doctor/options/DoctorFilterOptionListNotFoundError';
import { DoctorRepositoryToken } from '@/modules/tasks-list/di/tokens';

@injectable()
export class DoctorFilterOptionList {
  constructor(
    @inject(DoctorRepositoryToken)
    private readonly doctorRepository: DoctorRepository,
  ) {}

  getOptions(): DoctorFilterOption[] {
    return [...this.staticOptions, ...this.dynamicOptions];
  }

  getOptionByValue(searchedValue: string): DoctorFilterOption {
    const option = this.getOptions().find(({ value }) => value === searchedValue);

    if (!option) {
      throw new DoctorFilterOptionListNotFoundError(searchedValue);
    }

    return option;
  }

  get hasDoctors(): boolean {
    return this.doctorRepository.get().length > 0;
  }

  private get staticOptions(): DoctorFilterOption[] {
    return [EveryoneOption.make()];
  }

  private get dynamicOptions(): DoctorFilterOption[] {
    return this.doctorRepository
      .get()
      .sort((alpha, bravo) => alpha.fullName.localeCompare(bravo.fullName))
      .map(doctor => DoctorOption.make(doctor.id, doctor.fullName));
  }
}
