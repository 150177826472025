<template>
  <div class="app-status-banner">
    <div class="app-status-banner__body">
      <slot name="icon" />
      <div class="app-status-banner__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({ name: 'AppStatusBanner' })
export default class AppStatusBanner extends Vue {}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/mixins' as m;

.app-status-banner {
  display: block;
  height: v.$app-status-message;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  @include m.z-index(modal);
}

.app-status-banner__body {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;
}

.app-status-banner__content {
  text-align: center;
  padding: 0 0.25rem;
}
</style>
