import { inject, injectable } from 'tsyringe';
import { ErrorSenderToken, PresenceRepositoryToken } from '../di/tokens';
import type { PresenceRepository } from './PresenceRepository';
import type { ErrorSender } from './ErrorSender';
import { WaitingCallError } from './WaitingCallError';
import type { WaitingCall } from './WaitingCall';

@injectable()
export class WaitingCallChecker {
  constructor(
    @inject(PresenceRepositoryToken)
    private readonly presenceRepository: PresenceRepository,
    @inject(ErrorSenderToken)
    private readonly errorSender: ErrorSender,
  ) {}

  check(waitingCall: WaitingCall): void {
    if (!waitingCall.isUserRequired) {
      return;
    }

    const users = this.presenceRepository.all().map(user => ({ id: user.id, status: user.status }));
    const displayedUsers = users.filter(user => ['online', 'break'].includes(user.status));

    const isUserOnPresenceList = displayedUsers.some(user => user.id === waitingCall.userId);

    if (isUserOnPresenceList) {
      return;
    }

    this.errorSender.send(
      new WaitingCallError('User is not present on the presence list'),
      {
        call: {
          workstationMode: waitingCall.workstation?.mode,
          workstationId: waitingCall.workstation?.id,
          workstationUserId: waitingCall.userId,
          status: waitingCall.status,
        },
        presence: {
          users: JSON.stringify(users),
        },
        document: {
          visibilityState: document.visibilityState,
        },
      },
      ['waiting-call-error', 'user-list'],
    );
  }
}
