import { registry, delay } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { BaseUrlFactoryToken, HttpRequestExecutorToken } from '@/modules/http-client/di/tokens';
import { AxiosHttpRequestExecutor } from '@/modules/http-client/adapters/AxiosHttpRequestExecutor';
import { AppConfigBaseUrlFactory } from '@/modules/http-client/adapters/AppConfigBaseUrlFactory';

@registry([
  {
    token: HttpRequestExecutorToken,
    useToken: delay(() => AxiosHttpRequestExecutor),
  },
  {
    token: BaseUrlFactoryToken,
    useToken: delay(() => AppConfigBaseUrlFactory),
  },
])
export class HttpClientProvider implements DpProvider {}
