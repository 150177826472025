import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import type { ClientManager } from '../../domain/client/ClientManager';
import { CallbackFactoryToken, ClientManagerToken } from '../tokens';
import { PusherClientManager } from '../../adapters/client/PusherClientManager';
import type { CallbackFactory } from '../../domain/CallbackFactory';
import { StoreCallbackFactory } from '../../adapters/StoreCallbackFactory';

export class ClientProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.registerSingleton<ClientManager>(ClientManagerToken, PusherClientManager);
    container.register<CallbackFactory>(CallbackFactoryToken, StoreCallbackFactory);
  }
}
