import { RootStoreToken } from '@/common/root';
import type { DpProvider } from '@/core/di/DpProvider';
import { type DependencyContainer } from 'tsyringe';
import type { Store } from 'vuex';
import { pusher, storeName } from '../../ui/store/pusher';

export class StoreProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const store: Store<unknown> = container.resolve(RootStoreToken);
    store.registerModule(storeName, pusher);
  }
}
