import type { ClientManager } from '../../domain/client/ClientManager';
import type { Configuration } from '../../domain/configuration/Configuration';
import Pusher from 'pusher-js';
import { HttpAuthorizationRepository } from '../HttpAuthorizationRepository';
import { singleton } from 'tsyringe';
import { RealTimeCommunicationError } from '../../domain/RealTimeCommunicationError';
import { DpEventBus } from '@/core/events/DpEventBus';
import { ClientStateChanged } from '../../domain/events/ClientStateChanged';
import { ConnectionState } from '../../domain/connection/ConnectionState';

@singleton()
export class PusherClientManager implements ClientManager {
  private currentInstance: Pusher | null = null;

  private pendingClientConnection: Promise<Pusher> | null = null;

  constructor(
    private readonly authorizationRepository: HttpAuthorizationRepository,
    private readonly eventBus: DpEventBus,
  ) {}

  get state(): string {
    return this.currentInstance?.connection.state ?? '';
  }

  async make(configuration: Configuration): Promise<Pusher> {
    if (this.requiresNewInstance(configuration)) {
      this.currentInstance = this.createNew(configuration);
      return this.configureClient(this.currentInstance, configuration);
    }

    if (!this.currentInstance) {
      throw new RealTimeCommunicationError("Pusher instance doesn't exist");
    }

    return this.configureClient(this.currentInstance, configuration);
  }

  disconnect(): void {
    if (!this.currentInstance) {
      return;
    }
    this.leaveAllChannels(this.currentInstance);
    this.currentInstance.disconnect();
  }

  getCurrent(): Pusher {
    if (!this.currentInstance) {
      throw new RealTimeCommunicationError('Cannot find Pusher instance');
    }

    return this.currentInstance;
  }

  private leaveAllChannels(pusherInstance: Pusher): void {
    for (const channel of pusherInstance.allChannels()) {
      pusherInstance.unsubscribe(channel.name);
      channel.unbind_all();
    }
  }

  private leaveChannel(pusherInstance: Pusher, channelName: string) {
    const channel = pusherInstance.channel(channelName);
    channel.unbind_all();
    pusherInstance.unsubscribe(channelName);
  }

  private requiresNewInstance(configuration: Configuration): boolean {
    if (!this.currentInstance) {
      return true;
    }

    return this.currentInstance.key !== configuration.appKey;
  }

  private configureClient(pusher: Pusher, configuration: Configuration): Promise<Pusher> {
    pusher.connect();
    const validChannelNames = [configuration.presenceChannelId, configuration.privateChannelId];

    for (const channel of pusher.allChannels()) {
      if (validChannelNames.includes(channel.name)) {
        continue;
      }

      this.leaveChannel(pusher, channel.name);
    }

    pusher.subscribe(configuration.presenceChannelId);
    pusher.subscribe(configuration.privateChannelId);

    if (this.state === 'connected') {
      return Promise.resolve(pusher);
    }

    return this.waitForConnected(pusher);
  }

  private waitForConnected(instance: Pusher): Promise<Pusher> {
    /**
     * 1. if there is existing promise, return it
     * 2. build callback listener for state_change
     * 3. create a promise, that will resolve when listener change state to "connected"
     * 4. Add timeout promise that will reject if time is long
     * 5. clean up timeouts and pending promises
     */

    if (this.pendingClientConnection) {
      return this.pendingClientConnection;
    }

    this.pendingClientConnection = this.createWaitingPromise(instance);
    return this.pendingClientConnection;
  }

  private getStateChangeListener(callback: () => void) {
    return ({ current }: { current: string }) => {
      if (current === 'connected') {
        callback();
        return;
      }
    };
  }

  private async createWaitingPromise(pusher: Pusher): Promise<Pusher> {
    let stateChangeListener: (({ current }: { current: string }) => void) | undefined;
    let timeout: NodeJS.Timeout | undefined;

    const timeoutDelay = 10 * 1000;

    const promiseWaitingForPusher = new Promise<Pusher>((resolve, reject) => {
      stateChangeListener = this.getStateChangeListener(() => {
        resolve(pusher);
      });
      timeout = setTimeout(() => {
        reject(new RealTimeCommunicationError('Pusher creation request timed out'));
      }, timeoutDelay);

      pusher.connection.bind('state_change', stateChangeListener);
    });

    const validPusher = await promiseWaitingForPusher;

    if (!stateChangeListener || !timeout) {
      throw new RealTimeCommunicationError(
        "You're removing resolvers from pending promise for a pusher. It should never happen.",
      );
    }

    validPusher.connection.unbind('state_change', stateChangeListener);
    clearTimeout(timeout);

    return validPusher;
  }

  private createNew(configuration: Configuration): Pusher {
    const pusherInstance = new Pusher(configuration.appKey, {
      cluster: configuration.cluster,
      channelAuthorization: {
        transport: 'ajax',
        endpoint: '',
        customHandler: this.authorizationHandler.bind(this),
      },
    });

    pusherInstance.connection.bind('state_change', ({ current, previous }) => {
      this.eventBus.publish(new ClientStateChanged(new ConnectionState(current, previous)));
    });

    return pusherInstance;
  }

  private async authorizationHandler({ socketId, channelName }, callback) {
    try {
      const { auth, channel_data } = await this.authorizationRepository.auth(channelName, socketId);
      callback(null, { auth, channel_data });
    } catch {
      callback(true, new Error(`Cannot authorize user for this channel: ${channelName}`));
    }
  }
}
