import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { ErrorLoggerToken } from '@/modules/error-logger/di/tokens';
import { CombinedErrorLogger } from '@/modules/error-logger/adapters/logger/CombinedErrorLogger';
import type { ErrorLogger } from '@/modules/error-logger/domain/logging/ErrorLogger';

export class LoggerProvide implements DpProvider {
  register(container: DependencyContainer): void {
    container.register<ErrorLogger>(ErrorLoggerToken, CombinedErrorLogger);
  }
}
