import type { TextChannel } from './TextChannel';
import type { WhatsAppMessage } from '../message/WhatsAppMessage';
import type { ConversationRepository } from '../ConversationRepository';
import type { MediaRepository } from '../message/media/MediaRepository';
import type { PhoneNumber } from '../PhoneNumber';
import type { ConversationId } from '../ConversationId';

export class WhatsAppChannel implements TextChannel {
  readonly canSendVoiceRecordings = true;

  readonly canSendAttachments = true;

  readonly messageLengthLimit = 1600;

  constructor(
    private readonly messages: WhatsAppMessage[],
    private readonly messageRepository: ConversationRepository,
    private readonly mediaRepository: MediaRepository,
  ) {}

  get hasToInitializeWithTemplate(): boolean {
    return this.messages.length === 0;
  }

  get canSendFreeFormMessages(): boolean {
    const lastInboundMessage = [...this.messages]
      .reverse()
      .find(message => message.direction === 'inbound');

    if (!lastInboundMessage) {
      return false;
    }

    return lastInboundMessage.ageInHours < 24;
  }

  async sendMessage(
    conversationId: ConversationId,
    phoneNumber: PhoneNumber,
    body: string,
    mediaUrl?: string,
  ): Promise<void> {
    if (!conversationId.value) {
      throw new Error('Conversation ID is required');
    }

    if (mediaUrl) {
      const media = await this.mediaRepository.uploadFile(mediaUrl);

      return this.messageRepository.addMessage(conversationId, body, media.id);
    }

    return this.messageRepository.addMessage(conversationId, body);
  }
}
