import type {
  Task as RawTask,
  TaskVoicemail,
  TaskVoiceMenu,
  VisitConfirmation,
} from '@/api/types/entities/task';
import { TaskStatus, TaskTab, TaskType as ApiTaskType } from '@/api/types/entities/task';
import { TaskType as AppTaskType } from '@/app/task/task-type';
import { isPast, parseISO } from 'date-fns';
import type { User } from '@/api/types/entities/user';
import type { DateString, UUID } from '@/api/types';
import type { Context } from '@/api/types/entities/context';
import type { Result } from '@/api/types/entities/result';
import type { FormMessage } from '@/api/types/entities/form-message';
import { UpcomingVisit } from '@/api/models/UpcomingVisit';
import { Call } from '@/api/models/Call';
import type { ChannelName } from '@/api/types/entities/channel';
import { taskSchema } from '@/api/schemas/task-schema';
import { CallStatus } from '@/app/task/call-status';
import type { UserProfile } from '@/app/entities/UserProfile';
import { CallDirection } from '../types/entities/call';

export type TaskId = UUID;

interface Message {
  type: 'inbound' | 'outbound' | 'outbound-template';
  owner?: string;
}

export class Task {
  static schema = taskSchema;

  static readonly NewTaskId = 'new-task';

  id: TaskId;

  type: AppTaskType;

  anonymous: boolean;

  caller: string;

  comment: string | null;

  history: Call[];

  /**
   * Please use timeline module to obtain conversation.
   *
   * Required to correctly normalize data and populate entities store.
   *
   * @see {@link TimelineApi}
   * @deprecated
   */
  conversation: { id: string; messages: Message[] } | null;

  /**
   * Please use Patient module to obtain full patient profile.
   * Use `patientId` property instead;
   *
   * @see {@link PublicPatientApi}
   * @deprecated
   */
  patient: { id: string };

  patientId: string;

  phone: ChannelName;

  starred: boolean;

  status: TaskStatus;

  tab: TaskTab;

  voicemail?: TaskVoicemail;

  voice_menu?: TaskVoiceMenu;

  context?: Context;

  personnel?: User;

  doctors: { id: string }[];

  patient_upcoming_visit?: UpcomingVisit | null;

  patient_requests?: any[];

  result?: Result;

  form_message?: FormMessage;

  visit_confirmation?: VisitConfirmation;

  is_dp_chat_inbound_communication_allowed: boolean | null;

  readonly most_recent_call_at: DateString;

  readonly updated_at: DateString;

  readonly created_at: DateString;

  readonly expires_at?: DateString;

  readonly reminder_at: DateString | null;

  constructor({
    id,
    type = ApiTaskType.InboundNonReception,
    anonymous = false,
    caller,
    comment,
    history = [],
    conversation,
    patient,
    phone = { id: null, name: null },
    starred = false,
    status = TaskStatus.Open,
    tab,
    voicemail,
    voice_menu,
    context,
    personnel,
    doctors,
    patient_upcoming_visit,
    result,
    form_message,
    visit_confirmation,
    most_recent_call_at,
    updated_at,
    created_at,
    expires_at,
    reminder_at,
    patient_requests,
    is_dp_chat_inbound_communication_allowed,
  }: Partial<RawTask>) {
    const now = new Date().toDateString();

    this.id = id ?? Task.NewTaskId;
    this.type = Task.mapType(type);
    this.anonymous = anonymous;
    this.caller = caller ?? '';
    this.comment = comment ?? null;
    this.history = history.map(Call.make);
    this.conversation = conversation ?? null;
    this.patient = patient ?? { id: '' };
    this.patientId = patient?.id ?? '';
    this.phone = phone;
    this.starred = starred;
    this.status = status;
    this.tab = tab ?? TaskTab.Todo;
    this.voicemail = voicemail;
    this.voice_menu = voice_menu;
    this.context = context;
    this.personnel = personnel;
    this.doctors = doctors ?? [];
    this.patient_upcoming_visit = UpcomingVisit.tryMake(patient_upcoming_visit);
    this.result = result;
    this.form_message = form_message;
    this.visit_confirmation = visit_confirmation;
    this.most_recent_call_at = most_recent_call_at ?? now;
    this.updated_at = updated_at ?? now;
    this.created_at = created_at ?? now;
    this.expires_at = expires_at;
    this.reminder_at = reminder_at ?? null;
    this.patient_requests = patient_requests;
    this.is_dp_chat_inbound_communication_allowed =
      is_dp_chat_inbound_communication_allowed ?? null;
  }

  get formMessage(): FormMessage | null {
    return this.form_message ?? null;
  }

  get visitConfirmation(): VisitConfirmation | null {
    return this.visit_confirmation ?? null;
  }

  get voiceMenu(): TaskVoiceMenu | null {
    return this.voice_menu ?? null;
  }

  get patientUpcomingVisit(): UpcomingVisit | null {
    return this.patient_upcoming_visit ?? null;
  }

  get groupName(): TaskTab {
    return this.tab;
  }

  get updatedAt(): string {
    return this.updated_at;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get expiresAt(): string | null {
    return this.expires_at ?? null;
  }

  get mostRecentCallAt(): string {
    return this.most_recent_call_at;
  }

  get reminderAt(): string | null {
    return this.reminder_at;
  }

  get hasUpcomingVisit(): boolean {
    if (!this.isPending() && !this.isTodo()) {
      return false;
    }

    return this.patientUpcomingVisit?.hasVisitOutput ?? false;
  }

  get hasOnlyAbandonedCalls(): boolean {
    if (this.history.length === 0) {
      return false;
    }
    return this.history.every(call => call.status === CallStatus.Abandoned);
  }

  get hasReminder(): boolean {
    return !!this.reminderAt;
  }

  get isExpired(): boolean {
    return this.isExpiredStatus() || this.isExpiredTime();
  }

  get isOutboundVisitConfirmation(): boolean {
    return this.type === AppTaskType.OutboundVisitConfirmation;
  }

  get isInboundCall(): boolean {
    return this.type === AppTaskType.InboundCall;
  }

  get isWaiting(): boolean {
    return this.lastCallHasStatus(CallStatus.Waiting);
  }

  /** @deprecated */
  get isWaitingCall(): boolean {
    return this.isWaiting;
  }

  get isInProgress(): boolean {
    return this.lastCallHasStatus(CallStatus.InProgress);
  }

  /** @deprecated */
  get isInProgressCall(): boolean {
    return this.isInProgress;
  }

  get lastCall(): Call | null {
    if (!this.hasCall()) {
      return null;
    }

    return this.history[0];
  }

  isPending(): boolean {
    return this.groupName === TaskTab.Open;
  }

  isTodo(): boolean {
    return this.groupName === TaskTab.Todo;
  }

  isOutbound(): boolean {
    return this.type === AppTaskType.OutboundCall;
  }

  isInboundNonReception(): boolean {
    return this.type === AppTaskType.InboundNonReception;
  }

  isVisibleToAll(): boolean {
    return this.isTodo();
  }

  isAssigned(): boolean {
    return !!this.personnel;
  }

  canBeReopened(): boolean {
    return (
      !this.isOutboundVisitConfirmation &&
      !this.isInboundNonReception() &&
      !this.isExpiredTime() &&
      !this.isArchived() &&
      !this.hasConvertedVoicemail() &&
      this.isClosed()
    );
  }

  canBeReminded(): boolean {
    return !this.isClosed();
  }

  isClosed(): boolean {
    return [
      TaskStatus.Resolved,
      TaskStatus.VisitConfirmationSuccessful,
      TaskStatus.OnlineBookingConversion,
      TaskStatus.Abandoned,
      TaskStatus.Archived,
      TaskStatus.Expired,
    ].includes(this.status);
  }

  isExpiredStatus(): boolean {
    return this.status === TaskStatus.Expired;
  }

  isExpiredTime(): boolean {
    return this.expiresAt ? isPast(parseISO(this.expiresAt)) : false;
  }

  isArchived(): boolean {
    return this.status === TaskStatus.Archived;
  }

  hasConvertedVoicemail(): boolean {
    return this.voicemail?.converted ?? false;
  }

  isWhiteListed(): boolean {
    const whiteList = [
      AppTaskType.InboundCall,
      AppTaskType.InboundForm,
      AppTaskType.OutboundCall,
      AppTaskType.OutboundVisitConfirmation,
    ];

    return whiteList.includes(this.type);
  }

  isVisible(): boolean {
    return this.isClosed() || !this.isExpiredTime();
  }

  isNotAbandoned(): boolean {
    if (this.history.length === 0) {
      return true;
    }

    return this.history.some(call => call.status !== CallStatus.Abandoned);
  }

  hasCall(): boolean {
    return this.history.length > 0;
  }

  hasSuccessfulCall(): boolean {
    return this.history.some(call => call.isSuccessful());
  }

  hasIncomingCall(): boolean {
    return this.history.some(({ direction }) => direction === CallDirection.Inbound);
  }

  hasManyCalls(): boolean {
    return this.history.length > 1;
  }

  hasGroup(groupName: TaskTab): boolean {
    return this.groupName === groupName;
  }

  isLastCallUnanswered(): boolean {
    return this.lastCallHasStatus(CallStatus.Unanswered);
  }

  isOnHold(): boolean {
    return this.lastCall?.isOnHold ?? false;
  }

  isLastCallCompleted(): boolean {
    return this.lastCallHasStatus(CallStatus.Completed);
  }

  isAvailableToUser(user: UserProfile): boolean {
    if (user.isManager) {
      return true;
    }

    if (this.isVisibleToAll() || !this.isAssigned()) {
      return true;
    }

    return user.id === this.personnel?.id;
  }

  lastCallHasStatus(...statuses: CallStatus[]): boolean {
    const [lastCall] = this.history;

    return !!lastCall && statuses.includes(lastCall.status);
  }

  static make(data: RawTask): Task {
    if (!data.type) {
      return new Task({
        ...(data as RawTask),
        type: ApiTaskType.InboundNonReception,
      });
    }

    return new Task(data);
  }

  static makeEmptyTodo(): Task {
    return new Task({
      id: Task.NewTaskId,
      tab: TaskTab.Todo,
    });
  }

  static mapType(apiType: ApiTaskType): AppTaskType {
    return (
      {
        [ApiTaskType.InboundCall]: AppTaskType.InboundCall,
        [ApiTaskType.InboundForm]: AppTaskType.InboundForm,
        'text-conversation': AppTaskType.InboundCall,
        [ApiTaskType.InboundNonReception]: AppTaskType.InboundNonReception,
        [ApiTaskType.OutboundCall]: AppTaskType.OutboundCall,
        [ApiTaskType.OutboundVisitConfirmation]: AppTaskType.OutboundVisitConfirmation,
      }[apiType] ?? AppTaskType.InboundNonReception
    );
  }

  static denormalizeMake(data: RawTask): Task {
    return new Task(data);
  }
}
